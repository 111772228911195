import React, { Component, Fragment } from 'react'
import Layout from '../layout/layout';
import Hero from './hero/hero'
import ContactForm from './contact-from/ContactForm'
import AddressCard from './address-card/AddressCard'
import { Helmet } from "react-helmet";

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Contact Us | Power of Ten</title>
          <meta name="description" content="Ready to book your workshop or have a question? Contact us today." />
        </Helmet>
        <Layout>
          <Hero />
          <ContactForm />
          <AddressCard />
        </Layout>
      </Fragment>
    )
  }
}

export default Contact