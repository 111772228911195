import React, {Component, Fragment} from 'react'
import './Checkout.scss';
import Layout from '../layout/layout';
import Hero from './hero/hero'
import ContactInformation from './contact-information/ContactInformation'

class Checkout extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <ContactInformation/>
        </Layout>
      </Fragment>
    )      
  }
}

export default Checkout