import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import GettingStartedMenu from './getting-started-menu/GettingStartedMenu';
import ProductDetails from './product-details/ProductDetails';
import GameInstructions from './game-instructions/GameInstructions';
import RelatedGames from './related-games/RelatedGames';

class GameCategory extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          
          
          <ProductDetails/>
          <GameInstructions/>
          <RelatedGames/>
        </Layout>
      </Fragment>
    )
  }
}

export default GameCategory