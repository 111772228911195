import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import BlogDetails from './blog-details/BlogDetails';
import BlogContent from './blog-content/BlogContent';
import RelatedArticles from './related-articles/RelatedArticles';

class BlogArticle extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <BlogDetails/>
          <BlogContent/>
          <RelatedArticles/>
        </Layout>
      </Fragment>
    )
  }
}

export default BlogArticle