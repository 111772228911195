import React, {Component, Fragment} from 'react'
import "./hero.scss"

class Hero extends Component {
  render() {
    return (
      <Fragment>
          <div className="hero-section">

            <div className="container">

              <div className="columns">

                <div className="column">
                  <h5 className="has-text-white is-size-22-mobile is-size-29-tablet">Power of Ten</h5>
                  <h1 className="hero-heading is-size-26-mobile is-size-32-tablet my-0">Online Store</h1>
                </div>
              </div>

            </div>

          </div>
      </Fragment>
    )
  }
}

export default Hero