import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
// import GamePhotoTutorialImage from './images/game-photo-tutorial.png'
import "./GameInstructions.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';
function createMarkup(string) {
  return {__html: string};
}
class GameInstructions extends Component {
  constructor(props) {
       super(props);
       this.state = { products: [], img:[] };
     }
     componentDidMount(){
      let path = window.location.pathname
      let id = path.split('/');
      fetch(MyGlobleSetting.url + 'product/' + id[3])
      .then(response => response.json())
      .then(json => this.setState({ products: json }));

      let path1 = window.location.pathname
      let id1 = path1.split('/');
      fetch(MyGlobleSetting.url + 'product-img/' + id1[3])
      .then(response => response.json())
      .then(json => this.setState({ img: json[0] }));
    }
    componentWillReceiveProps(nextProps, nextState){
      let path = window.location.pathname
      let id = path.split('/');
      fetch(MyGlobleSetting.url + 'product/' + id[3])
      .then(response => response.json())
      .then(json => this.setState({ products: json }));

      let path1 = window.location.pathname
      let id1 = path1.split('/');
      fetch(MyGlobleSetting.url + 'product-img/' + id1[3])
      .then(response => response.json())
      .then(json => this.setState({ img: json[0] }));
    }
    static defaultProps = {
        modal: {},
    }
    state = {
      products:[],
      img:[]
    }
  render() {
    const { products,img } = this.state;
    return (
      Object.keys(products).map((val, k) => 
        <div k={k}>
      <Fragment>
          <div className="privacy-policy-form-section py-60px">

            <div className="container">

                <div className="privacy-policy-form-box mb-60px">

                    <h3 className="is-size-24 has-text-weight-bold is-inline-block mt-0 mb-30px">Game Instructions</h3>

                    {/* <h5 className="is-size-5 has-text-weight-bold mt-0px mb-5px">Privacy Policy of Power of Ten </h5> */}
                    
                    <p className="is-size-10 mb-20px"><div dangerouslySetInnerHTML={createMarkup(products[val].description)} /></p>
                    
                    {/*<p className="is-size-10 is-flex mb-20px"><strong className="mr-10px">1.</strong>  Player 1 chooses a card from the ‘pick-up’ pile in the centre.</p>
                    
                    <p className="is-size-10 is-flex mb-20px"><strong className="mr-10px">2.</strong>  The card is placed face up in the appropriate ‘spot’.</p>
                    
                    <p className="is-size-10 is-flex mb-20px"><strong className="mr-10px">3.</strong>  She turns over the card that was face down in that ‘spot’ and sees if it fits in a remaining ‘spot’ (whatever is not face up in the number line).</p>
                    
                    <p className="is-size-10 is-flex mb-20px"><strong className="mr-10px">4.</strong>  She continues until the card turned over cannot be used in the number line, either because it is already there or it does not fit in the number line.</p>
                    
                    <p className="is-size-10 is-flex mb-20px"><strong className="mr-10px">5.</strong>  If the card cannot be used, she places it in a ‘discard’ pile (face up).</p>
                    
                    <p className="is-size-10 is-flex mb-20px"><strong className="mr-10px">6.</strong>  Player 2 takes a turn and can draw from the ‘pick-up’ pile or the ‘discard’ pile.</p>*/}
                    
                    <Link to="#" className="has-text-primary is-size-10 has-text-weight-bold">View a visual representation below.</Link>

                </div>

                <div className="text-center px-60px">
                  <img src={MyGlobleSetting.gameimgurl + img.image} alt="" className=""/>
                </div>

            </div>

          </div>
      </Fragment>
      </div>
      )
    )
  }
}

export default GameInstructions