import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyGlobleSetting from "../../../../MyGlobleSetting";
import "./GettingStartedMenu.scss";

class GettingStartedMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { categories: [] };
    this.myDivToFocus = React.createRef();
  }
  componentDidMount() {
    fetch(MyGlobleSetting.url + "categories")
      .then((response) => response.json())
      .then((json) => this.setState({ categories: json }));
  }
  static defaultProps = {
    modal: {},
  };
  state = {
    categories: [],
  };

  render() {
    const { categories } = this.state;
    return (
      <Fragment>
        <div className="bg-gray-light py-25px">
          <div className="container">
            <div className="store-page-category-menu">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      document.getElementById("cards").scrollIntoView();
                    }}>
                    Power of Ten Cards{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      document
                        .getElementById("powerofTenKits")
                        .scrollIntoView();
                    }}>
                    Power of Ten Kits
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      document
                        .getElementById("interactiveLessions")
                        .scrollIntoView();
                    }}>
                    Power of Ten Manuals
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      document.getElementById("orderForm").scrollIntoView();
                    }}>
                    Printable Order Form
                  </a>
                </li>

                {/* {
                          categories.map((category) => (
                            <li key={category.id}><Link to="/" className="">{category.name}</Link></li>
                            ))
                        } */}
              </ul>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '90%', background: '#1682C5', borderRadius: '20px', width: 'fit-content', padding: '24px', margin: '32px auto 0' }}>
          <h2 style={{ color: '#fff', fontSize: '20px' }}>Welcome to our new store. If you have any issues please contact us at anita@poweroften.ca</h2>
        </div>
      </Fragment>
    );
  }
}

export default GettingStartedMenu;
