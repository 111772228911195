import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
// import Slider from "react-slick"

import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import {ReactComponent as RightArrowIcon} from "./images/ic_up_arrow.svg"
import {ReactComponent as LeftArrowIcon} from "./images/ic_up_arrow.svg"
import "./BrowseAll.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';

class BrowseAll extends Component {
    constructor(props) {
        super(props);
        this.state = { products: [], featuredproducts:[],pageNumber: 1};
      }

      getData(pageNumber) {
        fetch(MyGlobleSetting.url + 'blogs/'+pageNumber)
        .then(response => response.json())
        .then(json => this.setState({ products: json }));
    }
    async componentDidMount(){
      
        await this.getData(this.state.pageNumber);

       fetch(MyGlobleSetting.url + 'products/featured')
       .then(response => response.json())
       .then(json => this.setState({ featuredproducts: json.data }));
       
       }
     static defaultProps = {
         modal: {},
     }
     state = {
       products:[],
       featuredproducts:[]
 
     }

     handleNext = () => {
        if (this.state.pageNumber < 2) {
            var n = this.state.pageNumber;
            n = n + 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }
    handleBack = () => {
        if (this.state.pageNumber >= 1) {
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }
    

    render() {
        const { products, featuredproducts } = this.state;

        return (
        
        <Fragment>

            <div className="browse-all-section py-100px bg-light-gray">

                <div className="container">

                    <div className="mb-40px">
                        <h1 className="mb-20px is-size-24 has-text-weight-bold">Browse All</h1>                        
                    </div>

                    <div className="full-free-collection-grid">

                        { products.map((product) => (
                            <div className="slider-item-box" key="{product.id}">
                        
                                <div className="slider-item" >
                            
                                <img src={MyGlobleSetting.blogimgurl + product.main_image} alt="" className="slider-item-image"/>
                            
                                <div className="slider-item-caption">
                                    <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                    <p className="is-size-7 mb-15px">{product.description}</p>
                                    <Button className="button is-size-10 is-small is-primary is-rounded mb-0">Learn More</Button>
                                    <Link to={`/blog/article/${product.id}/${product.bcategory_id}`} className="slider-link-button">
                                        <span className="slider-link-button-inner-text"> Learn More </span>
                                        <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>
                                    </Link>
                                </div>
                            
                                </div>
                            
                            </div>
                        ))}

                    </div>

                    <div className="p-30px">
                        <ul className="full-free-collection-pagination">
                            <li> 
                            <a onClick={this.handleBack}>
                                    <span className="pagination-icon prev">  <LeftArrowIcon/>   </span>
                                    <span className="pagination-text">Prev</span>
                                </a> 
                            </li>
                            <li> 
                            <a onClick={this.handleNext}>
                                    <span className="pagination-icon next">  <RightArrowIcon/>   </span>
                                    <span className="pagination-text">Next</span>
                                </a> 
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default BrowseAll