import React, { Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./Author.scss"
import RedGridCardImage from './images/red-grid-card.png'
import { Parallax } from 'react-parallax';
import AuthorThumb from './images/trevor.png'

class Author extends Component {
  render() {
    return (
      <Fragment>
        <div className="py-100px author-section">

          <div className="container">

            <div className="columns is-variable is-5">

              <div className="column is-7">

                <div className="pr-30px">
                  <h3 className="is-size-24 has-text-weight-bold mr-30px is-inline-block mb-30px">
                    Meet the Author
                  </h3>

                  <p className="is-size-8 mb-10px">
                    Trevor Calkins, creator and author of Power of Ten, has spent his adult life studying how children develop numeracy. As teacher and administrator, he thoughtfully observed children struggling with numeracy skills, who “just can’t do math”. Equally, teachers expressed frustration about their inability to “get through to the kids”.
                  </p>

                  <p className="is-size-8 mb-10px">
                    After years of observation, questioning, and studying, Trevor concluded that many of the old methods of teaching numeracy and mathematics were not working; new approaches were required.
                  </p>

                  <p className="is-size-8 ">
                    Combining his experience with formal study of current brain research, Trevor has developed a system which guides teachers and give students a fresh and exciting way to develop numeracy skills.
                  </p>
                  <div style={{paddingTop: "25px"}} className="is-flex is-align-items-center">

                  <div className="mr-25px">
                      <img src={AuthorThumb} alt="" className="owner-small-img" width="92" />
                    </div>

                    <div>
                      <h4 className="has-text-weight-bold is-size-10">Trevor Calkins</h4>
                      <span className="is-size-5">Creator and Author of Power of Ten Educational Consulting Ltd.</span>
                    </div>

                  </div>
                </div>

              </div>

              <div className="column is-5 is-relative">

                <div className="py-60px px-50px sky-blue-card box-shadow mt-60px">

                  <h3 className="has-text-white has-text-weight-semibold is-italic is-size-22 is-inline-block">
                    Power of Ten is the result of Trevor’s effort.
                  </h3>

                </div>

                <div className="about-author-red-grid-card-shape">
                  <Parallax blur={0} bgImage={RedGridCardImage} bgImageAlt="Home Hero Background Image" strength={80}></Parallax>
                </div>


                {/* <div className="red-grid-card-shape">
                    <img src={RedGridCardImage} alt="" className="" />
                  </div> */}

              </div>

            </div>

          </div>

        </div>
      </Fragment>
    )
  }
}

export default Author