// require('./bootstrap');
import React, { useEffect } from "react";
// import { render } from 'react-dom';
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Master from './components/Master';
// import DisplayProduct from './components/DisplayProduct';
import ScrollTop from "./view/component/scroll-to-top/ScrollToTop";
import Home from "./view/home/home";
import Store from "./view/store/online-store/OnlineStore";
import ShopAllKits from "./view/store/shop-all-kits/ShopAllKits";
import ShopAllCards from "./view/store/shop-all-cards/ShopAllCards";
import ShopAllLesson from "./view/store/shop-all-lesson/ShopAllLesson";
import StoreProductDetails from "./view/store/store-product-details/StoreProductDetails";
import Workshopes from "./view/workshops/workshops";
import Freebies from "./view/freebies/freebies-list/FreebiesList";
import FreeDownload from "./view/freebies/free-download/FreeDownload";
import About from "./view/about/about";
import LessonsAll from "./view/lessons/all/All";
import LessonsVideoLessons from "./view/lessons/video-lessons/VideoLessons";
import SmartBoardLessons from "./view/lessons/smart-board-lessons/SmartBoardLessons";
import Games from "./view/games/getting-started/GettingStarted";
import GamesCategory from "./view/games/game-category/GameCategory";
import Addition from "./view/games/addition/Addition";
import Substraction from "./view/games/substraction/Substraction";
import Multiplication from "./view/games/multiplication/Multiplication";
import Contact from "./view/contact/contact";
import PrivacyPolicy from "./view/privacy-policy/PrivacyPolicy";
import Blog from "./view/blog/blog/Blog";
import BlogArticle from "./view/blog/blog-article/BlogArticle";
import Cart from "./view/cart/Cart";
import Page404 from "./view/component/page-not-found/PageNotFound";
import Checkout from "view/checkout/Checkout";
import Success from "view/checkout/Success";
import { loadReCaptcha } from "react-recaptcha-google";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Newsletters from "view/newsletters/newsletters";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
loadReCaptcha();

export default function AppCom() {
  document.addEventListener("DOMContentLoaded", () => {
    //document.getElementsByName("loading")[0].style.display  = 'none';
    document.querySelectorAll(".loading")[0].style.visibility = "hidden";
  });

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-KB9DVWGK1Z",
      },
    ]);
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('217861240053614', options);
    ReactPixel.pageView();
  }, []);

  return (
    <Router>
      {/* <Loader
        // visible="true"
        type="Puff"
        color="#1682C5"
        height={100}
        width={100}
        timeout={3000} //3 secs
        className="site-loader"
      /> */}
      <div class="loading">Loading&#8230;</div>
      <ScrollTop>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/workshops" component={Workshopes} />
          <Route exact path="/store" component={Store} />
          <Route
            exact
            path="/store/shop-all-kits/:id"
            component={ShopAllKits}
          />
          <Route
            exact
            path="/store/shop-all-cards/:id"
            component={ShopAllCards}
          />
          <Route
            exact
            path="/store/shop-all-lesson/:id"
            component={ShopAllLesson}
          />
          <Route
            exact
            path="/store/product-details/:id"
            component={StoreProductDetails}
          />
          <Route exact path="/lessons" component={LessonsAll} />
          <Route
            exact
            path="/lessons/video-lessons"
            component={LessonsVideoLessons}
          />
          <Route
            exact
            path="/lessons/smart-board-lessons/:id"
            component={SmartBoardLessons}
          />
          <Route exact path="/games" component={Games} />
          <Route exact path="/games/category/:id" component={GamesCategory} />
          <Route exact path="/games/addition" component={Addition} />
          <Route exact path="/games/substraction" component={Substraction} />
          <Route
            exact
            path="/games/multiplication"
            component={Multiplication}
          />
          <Route exact path="/freebies" component={Freebies} />
          <Route
            exact
            path="/freebies/free-download/:id"
            component={FreeDownload}
          />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/blog" component={Blog} />
          <Route
            exact
            path="/blog/article/:id/:catid"
            component={BlogArticle}
          />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/success" component={Success} />
          <Route exact path="/newsletters" component={Newsletters} />
          <Route path="*" component={Page404} />
        </Switch>
      </ScrollTop>
    </Router>
  );
}
