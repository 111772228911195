import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import "./OurPhilosophy.scss"
import Line from './images/line.png'
import OwnerThumb from './images/anita-smallimage.jpg'

class OurPhilosophy extends Component {

  state = {
    items: [
          {
            uuid: 1,
            heading: 'Meaning',
            content: `
              <p> When there is meaning, mathematics is easier to learn and much easier to remember. Meaning is usually found in complex activities such as: problem posing, problem solving, and working on projects.  </p>
              <p>  Sports, cooking, nutrition, data collection, measurement, and student-created puzzles are excellent sources of meaning. </p>
              <p>  Projects – designing and planning a four-room house, planning a party, purchasing school supplies, or picking stocks – bring mathematics to life and connect to the student’s community.  </p>
            `,
          },
          {
            uuid: 2,
            heading: 'Choice',
            content: `
              <p> The brain has difficulty remembering material that is not connected to previous learning. The more choice built into the learning process – the greater the success of learning. <br/><br/>Choice is provided when students generate student-written problems and when they are allowed to write tests or “All the Facts” sheets in order of their choosing. Students doing the “How Many Ways” and “What Do I Know” activities find themselves challenged and during the participation in these activities many students become very creative with ‘number’. In addition, the games which are provided further give students ‘choice” as there are many levels of challenge. Projects almost always involve students choosing information relevant to their homes, school or community.  </p>
            `,
          },
          {
            uuid: 3,
            heading: 'Trust',
            content: `
              <p> Students who are asked “How did you get your answer?” or “Can you do it another way?" learn to feel that their methods and ideas are valued. Trusting that your peers and teachers value your thinking is an important step in creating self-esteem and risk-taking in mathematics. Teachers who use this approach develop increasingly respectful questioning techniques that encourage creativity and risk-taking. <br/><br/>Students develop an “I can” mindset
              with regards to math.</p>
            `,
          },
          {
            uuid: 4,
            heading: 'Diversity',
            content: `
              <p> Because all students bring different experiences to the classroom, they learn differently and make connections differently. When this diversity is valued by teachers and students, a sense of risk-taking envelops the class and the whole process of learning mathematics becomes creative and joyful.  </p>
            `,
          },
          {
            uuid: 5,
            heading: 'Time',
            content: `
              <p> Traditional textbooks often teach in units – some topics such as decimals, fractions, geometry, measurement and even multiplication and division are not taught until later in the school year. This means that students receive less practice in these areas and often forget the material as there can be as many as twelve months between units. Because their previous learning experiences are so varied, all students require ‘differing amounts of time’ to learn new material. <br/><br/> The curriculum should be learned, reviewed, and re-learned within new contexts, such as projects, Daily Quiz, Problem Solving and Problem Posing. The Yearly Plans in the Teachable Moments Manual ensure that the main concepts throughout the entire curriculum are covered monthly. <br/><br/> Assessment drives instruction. Mastery is developed over long periods of time. This approach to math instruction parallels the way reading is taught and language is learned.  </p>
            `,
          },
      ],
  }

  render() {

    const { items } = this.state;
    

    return (
      <Fragment>
        <div className="our-philosophy-section pt-40px pb-100px">

          <div className="container">

            <div className="columns is-variable is-1-mobile is-5-tablet is-3-desktop is-8-widescreen is-2-fullhd">

              <div className="column">
                  <h3 className="owner-heading is-size-24 has-text-weight-bold mb-30px is-inline-block">Our Philosophy</h3>
                  <div className="custom-accordion-box">
                      <Accordion allowZeroExpanded preExpanded={[1]}>
                        {items.map((item) => (
                            <AccordionItem key={item.uuid} uuid={item.uuid}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.heading}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel dangerouslySetInnerHTML={{__html: item.content}}>
                                  {/* {item.content} */}
                                  {/* <div > </div> */}
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                  </div>
              </div>

              <div className="column">
              
                <h3 className="owner-heading is-size-24 has-text-weight-bold mb-30px is-inline-block">Meet the Owner</h3>
                
                <p className="is-size-8 mb-30px">
                  Anita was introduced to Power of Ten early in her teaching
                  career. The philosophy, activities and approach resonated
                  strongly and in 2009, Anita further trained with Trevor Calkins
                  and became a Power of Ten Consultant. Anita has been
                  supporting teachers improving their practices and spiraling their
                  math curriculum by sharing the philosophy of teaching the
                  entire math curriculum monthly. In August 2018, she bought the
                  company from Trevor when he retired. Anita is passionate
                  about sharing the Power of Ten approach for mathematics
                  instruction. <br/><br/>
                  Anita Horsman (Payan) is the owner of Power of Ten
                  Educational Consulting Ltd. She prepares and delivers math
                  workshops in English or French, supporting teachers,
                  administrators, home educators and parents in their quest for
                  improved mathematics instruction. Anita has presented
                  workshops to teachers in BC, AB, ON, NS, NB and NY.<br/><br/>
                  Over the past 20+ years, she has helped her own classroom
                  students develop solid skills in the fundamentals of math. Anita
                  has a Bachelor of Education Degree from Université de
                  Moncton and a Diploma in Advanced Undergraduate Studies
                  with majors in Numeracy and Literacy from UNB.<br/><br/>
                  Anita is excited to be bringing Trevor’s creation to today’s math
                  teachers.
                </p>
                
                <div className="is-flex is-align-items-center"> 

                  <div className="mr-25px">
                    <img src={OwnerThumb} alt="" className="owner-small-img" width="92" />
                  </div>

                  <div>
                    <h4 className="has-text-weight-bold is-size-13">Anita Horsman (Payan)</h4>
                    <span className="is-size-8">Owner of Power of Ten Educational Consulting Ltd.</span>
                  </div>

                </div>

              </div>

            </div>

          </div>
          
          <div className="dash-line">
            <img src={Line} alt="" className="bottom-line" />
          </div>

        </div>
      </Fragment>
    )
  }
}

export default OurPhilosophy