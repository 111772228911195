import React, {Component, Fragment} from 'react'
import "./hero.scss"

class Hero extends Component {
  render() {
    return (
      <Fragment>
          <div className="hero-section">

            <div className="container">

              <div className="columns">

                <div className="column is-four-fifths">
                  <h1 className="hero-heading mt-0 mb-30px">Freebies</h1>
                </div>
                
              </div>

            </div>

          </div>
      </Fragment>
    )
  }
}

export default Hero