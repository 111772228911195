import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import "./Power.scss"
import HandImage from "./images/hand-card.png"
import RedGridCardImage from "./images/red-grid-card.png"
import { Parallax } from 'react-parallax';

class Power extends Component {
  render() {
    return (
        <Fragment>
            <div className="py-130px is-relative z-index-0">

                <div id="testimonials" style={{maxWidth: "1000px"}} className="container">
                
                    <div className="px-30px text-center">

                        <h3 className=" is-size-22-mobile is-size-26-tablet has-text-weight-bold is-inline-block mb-15px">
                            Testimonials
                        </h3>
                        <h6 className="is-size-12-mobile is-size-16-tablet mb-30px">
                            It is great to have educators with success stories!  Please share your success story with us as we love reading them.
                        </h6>
                        
                        <p className="is-size-7 mb-30px">
                        “The Power of Ten Resource is a highly visual approach to teaching math. I am amazed how fast struggling students develop subitizing and fact fluency by using the games and making the connections to basic fact mastery by connecting the games to the All the Facts for adding, subtracting, multiplying and dividing. In one year of tutoring, my student has come from mid grade one to comfortable grade 4. She has confidence and strategies to solve problems as they are presented. Next to gym, math is her favourite subject. After 6 months working with this student, I asked her how many questions she thought she got correct. Her answer was a surprised look and a confident “all of them!”  and she was right. A great resource for all classrooms to foster the love of math and the I Can do attitude without fear of failure." 
                        <br/><br/>Ellen, Retired Teacher, Moncton, NB  
                        </p>

                        <p className="is-size-7 mb-30px">
                        “Love the Power of Ten games!  I have used them with K-3 students (in French) and they are a hit at every level.  I also really love how the number of the day is set up for students to push themselves and learn from one another. ‘How Many Pockets’ has my students arriving every Wednesday (our designated day) dressed and ready to go with as many pockets as they can sport. Power of Ten makes number make sense!" 
                        <br/><br/>Shelley, Teacher, Cold Lake, AB 
                        </p>

                        <p className="is-size-7 mb-30px">
                        “…I just wanted to let you know that my grade 2 teacher (13 years ago) used these [Power of Ten cards] in our class to help us with our addition and subtraction skills and ever since I've always visualized the numbers in the power of ten format, like how certain numbers shape wise interlock within the grid. Just wanted to let you guys know that this has been super helpful and now as a student at the university of Lethbridge pursuing a combined degree in science and math education I can still look back to the power of ten system in helping me in my math journey and is something I will 100% use in my class once I become a teacher. I actually had my first education class this semester and thought I’d search to see if you guys were still around and I’m pleased to see you guys are still here and better than ever! just wanted to say thanks and the power of ten cards will definitely be used in my classroom in the future." 
                        <br/><br/>Andrew, Student, Lethbridge, AB
                        </p>

                        <p className="is-size-7 mb-30px">
                        “We have used the Power of Ten program for the past 29 years in our teaching.  The Power of Ten cards help children visualize numbers and gives them strategies to fully understand numbers and number operations. Students simply love to play games with the cards. This allows for daily practice with minimal teacher preparation, as well as an opportunity for the teacher to easily assess students. We love the flexibility and creativity of using the Teachable Moments. Providing children with real experiences gets them excited about math. Our students have been so successful using the Power of Ten that we have shared our experiences with other teachers through workshops.  The Power of Ten has something to offer every teacher and most importantly every student."" 
                        <br/><br/>Our students have been so successful using the Power of Ten that we have shared our experiences with other teachers through workshops.  The Power of Ten has something to offer every teacher and most importantly every student.”
                        <br/><br/>Colleen Neves and Marjorie Ireton Roach - K/1 Teachers, Victoria, BC 
                        </p>

                        {/*<Link to="" className="button is-size-10 primary-link-button is-primary-red is-rounded">Learn More</Link>*/}
                        
                    </div>

                </div>

                {/* <div className="about-power-hand-red-grid-card-shape">
                    <img src={HandImage} alt="" className="about-power-hand-shape-image" />
                    <img src={RedGridCardImage} alt="" className="about-power-red-grid-card-image" />
                </div> */}

                <div className="about-power-hand-red-grid-card-shape">
                    
                    <div className="about-power-hand-shape-image">
                        <Parallax blur={0} bgImage={HandImage} bgImageAlt="Home Hero Background Image" strength={30}></Parallax>
                    </div>
                    
                    <div className="about-power-red-grid-card-image">
                        <Parallax blur={0} bgImage={RedGridCardImage} bgImageAlt="Home Hero Background Image" strength={30}></Parallax>
                    </div>
                    
                </div>

            </div>
        </Fragment>
    )
  }
}

export default Power