import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Redirect, Link  } from "react-router-dom"
import Modal from 'react-bulma-components/lib/components/modal'
import ReactPlayer from "react-player"
// import Slider from "react-slick"
// import SliderBgImage from "./images/game-image.png"
// import SliderBgImage1 from "./images/lesson1.png"
// import SliderBgImage2 from "./images/lesson2.png"
// import SliderBgImage3 from "./images/lesson3.png"
// import SliderBgImage4 from "./images/lesson4.png"
// import SliderBgImage5 from "./images/lesson5.png"
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import {ReactComponent as RightArrowIcon} from "./images/ic_up_arrow.svg"
import {ReactComponent as LeftArrowIcon} from "./images/ic_up_arrow.svg"
import "./AllVideoLessons.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';

class AllVideoLessons extends Component {
    constructor(props) {
       super(props);
       this.state = { products: [], selectedPost: null,pageData:[] , pageNumber:1 };

     }
     getData(pageNumber){
        fetch(MyGlobleSetting.url + 'product-cat/13/29/'+pageNumber)
        .then(response => response.json())
        .then(json => this.setState({ products: json }));
    }
     // componentDidMount(){
     //  fetch(MyGlobleSetting.url + 'product-cat/13/29')
     //  .then(response => response.json())
     //  .then(json => this.setState({ products: json }));
     //  }
     handleNext = ()=>{
        if(this.state.products.length > 0){
        var n = this.state.pageNumber;
        n = n + 1;
        this.setState({pageNumber:n})
        this.getData(n);
         }
     }
     handleBack = ()=>{
        if(this.state.products.length >= 0){
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({pageNumber:n})
            this.getData(n);
        }
     }

     open = i => 
     this.setState({ show: true, selectedPost: i });
 
    close = () => this.setState({ show: false });
 
     async componentDidMount(){ 
        await this.getData(this.state.pageNumber);
      }
    static defaultProps = {
        modal: {},
    }
    state = {
        products:[],
        pageData : [],
        show: false,
        url1 : ""
    } 
    display = () => {
        if(this.state.selectedPost !== null){
            const path1  = window.location.href;
            const page1 = path1.split('=');
            const url = '?page=' + page1[0];
            console.log(url);
            return <Redirect to={url}    />
        }
    }
    renderModal = () => {
        // Check to see if there's a selected post. If so, render it.
        if (this.state.selectedPost !== null) {
          const post = this.state.products[this.state.selectedPost];
          const h3_head = { color: '#FFFFFF'};
    
          return (
            
            <div className="modal-card video-modal-card">
                <h3 className="video-modal-heading is-size-4 has-text-weight-bold mt-0 mb-20px" style={h3_head}>{post.title}</h3>
                <ReactPlayer url={post.video_url} />
            </div>
          );
        }
      }
    render() {
        
        const { products } = this.state;

        return (
        
        <Fragment>

            <div className="full-free-collection-section py-100px">

                <div className="container">

                    <div className="">
                        <span className="is-size-8">Lessons / Videos of Games and Lessons</span>
                        <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">Videos of Games and Lessons</h3>
                    </div>

                    <div className="full-free-collection-grid">

                        {products.map((product,i) => (
                            <div className="slider-item-box">
                        
                                <div className="slider-item">
                                <div style={{cursor: 'pointer'}} onClick={() => this.open(i)} className="">
                                <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image"/>
                                <span className="slider-item-top-badge">Video</span>
                                <div className="slider-item-caption">
                                    <h5 className="mb-15px"> {product.title}</h5>
                                    <p className="mb-15px">{product.summary}</p>
                                    <Button className="button is-small is-primary is-rounded mb-0">Learn More</Button>
                                     <a to="" onClick={() => this.open(i)} sclassName="slider-link-button">
                                        {/*<span className="slider-link-button-inner-text"> Learn More </span>
                                        <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>*/}
                                    </a> 
                                    
                                        {/*<span className="slider-link-button-inner-text"> Learn More </span>
                                        <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>*/}
                                    
                                </div>
                                </div>
                                </div>
                            
                            </div>
                        ))}

                    </div>

                    <div className="p-30px">
                             <ul className="full-free-collection-pagination">
                                <li> 
                                    <a onClick={this.handleBack}> 
                                        <span className="pagination-icon prev">  <LeftArrowIcon/>   </span>
                                        <span className="pagination-text">Prev</span>
                                    </a> 
                                </li>
                                <li> 
                                    <a  onClick={this.handleNext}> 
                                        <span className="pagination-icon next">  <RightArrowIcon/>   </span>
                                        <span className="pagination-text">Next</span>
                                    </a> 
                                </li>
                            </ul>
                    </div>

                </div>

            </div>
            <Modal className="modal" show={this.state.show} onClose={this.close} {...this.props.modal} modal={{ closeOnBlur: true, showClose: false }}>
                <div className="modal-background"></div>
                <div>{this.renderModal()}</div>
            </Modal>
                  
        </Fragment>
        
        )

    }
  
}

export default AllVideoLessons