import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import GettingStartedMenu from './getting-started-menu/GettingStartedMenu';
import LessionVideoDetails from './lession-video-details/LessionVideoDetails';

class SmartBoardLessons extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <GettingStartedMenu/>
          <LessionVideoDetails/>          
        </Layout>
      </Fragment>
    )
  }
}

export default SmartBoardLessons