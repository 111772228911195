import React, { Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import Modal from 'react-bulma-components/lib/components/modal'
import { Link, Redirect } from "react-router-dom"
import ReactPlayer from "react-player"

import { ReactComponent as LightbulbIcon } from "./images/ic_lightbulb.svg"
import { ReactComponent as RightArrowIcon } from "./images/ic_up_arrow.svg"
import { ReactComponent as LeftArrowIcon } from "./images/ic_up_arrow.svg"
import "./VideoLessons.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';
// import VideoPath from "./video/dammy-video.mov"

class VideoLessons extends Component {

    constructor(props) {
        super(props);
        this.state = { products: [], selectedPost: null, page: '', pageData: [], pageNumber: 1, show: false, url1: "" };
    }
    static defaultProps = {
        modal: {},
    }
    substr1(str) {
        return str.substr(0, 80) + "...";
    }
    getData(pageNumber) {
        fetch(MyGlobleSetting.url + 'product-cat/13/29/' + pageNumber)
            .then(response => response.json())
            .then(json => this.setState({ products: json, pageData: json }));
    }
    async componentDidMount() {
        await this.getData(this.state.pageNumber);
    }
    handleNext = () => {
        if (this.state.pageNumber < 2) {
            var n = this.state.pageNumber;
            n = n + 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }
    handleBack = () => {
        if (this.state.pageNumber >= 1) {
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }

    //open = () => this.setState({ show: true });
    display = () => {
        if (this.state.selectedPost !== null) {
            const path1 = window.location.href;
            const page1 = path1.split('=');
            const url = '?page=' + page1[0];
            console.log(url);
            return <Redirect to={url} />
        }
    }
    open = i => this.setState({ show: true, selectedPost: i });

    close = () => this.setState({ show: false });

    renderModal = () => {
        // Check to see if there's a selected post. If so, render it.
        if (this.state.selectedPost !== null) {
            const post = this.state.products[this.state.selectedPost];
            const h3_head = { color: '#FFFFFF' };

            return (

                <div className="modal-card video-modal-card">
                    <h3 className="video-modal-heading is-size-4 has-text-weight-bold mt-0 mb-20px" style={h3_head}>{post.title}</h3>
                    <ReactPlayer url={post.video_url} />
                </div>
            );
        }
    }
    render() {

        const { products, show } = this.state;

        return (

            <Fragment>

                <div className="full-free-collection-section py-100px">

                    <div className="container">

                        <div className="">
                            <span className="is-size-8">Lessons / All</span>
                            <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">Video Lessons</h3>
                        </div>

                        <div className="full-free-collection-grid">

                            {products.map((product, i) => (

                                <div className="slider-item-box" key={product.id}>

                                    <div className="slider-item">
                                        <Link onClick={() => this.open(i)} className="">

                                            <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image" />

                                            <span className="slider-item-top-badge is-size-2">Video</span>

                                            <div className="slider-item-caption">

                                                <h5 className="is-size-11 mb-15px"> {product.title}</h5>

                                                <p className="is-size-7 mb-15px">{this.substr1(product.summary)}</p>

                                                <Button className="button is-small is-primary is-size-5 is-rounded mb-0">Learn More</Button>
                                                {/*<span className="slider-link-button-inner-text"> Learn More </span>
                                                <span className="slider-link-button-inner-icon"> <LightbulbIcon /> </span>*/}


                                            </div>
                                        </Link>
                                    </div>

                                </div>

                            ))}

                        </div>

                        <div className="p-30px">
                            <ul className="full-free-collection-pagination">
                                <li>
                                    <a onClick={this.handleBack}>
                                        <span className="pagination-icon prev">  <LeftArrowIcon />   </span>
                                        <span className="pagination-text">Prev</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={this.handleNext}>
                                        <span className="pagination-icon next">  <RightArrowIcon />   </span>
                                        <span className="pagination-text">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div style={{paddingTop: "25px"}} className="is-size-7 has-text-weight-bold">
                            <p>
                                For more detailed instructions, refer to the Teachable Moments Manual, Games Chapter, or go to the Freebies page on the website and download the Games chapter for free.
                            </p>
                        </div>
                    </div>

                </div>

                {this.state.show &&
                    <div className="modal modal modal is-active">
                        <div className="modal-background"></div>
                        <div>{this.renderModal()}</div>
                        <button className="modal-close is-large" onClick={this.close} aria-label="close"></button>
                    </div>
                }

                {/* <Modal className="modal" show={this.state.show} onClose={this.close} {...this.props.modal} modal={{ closeOnBlur: true, showClose: false }}>
                    <div className="modal-background"></div>
                    <div>{this.renderModal()}</div>
                </Modal> */}

            </Fragment>

        )

    }

}

export default VideoLessons