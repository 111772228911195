import React, { Component, Fragment } from 'react'
// import '../../App.scss';
import Layout from '../layout/layout';
import Hero from './hero/hero';
import Courses from './courses/courses';
import Games from './games/games';
import Teaching from './teaching/teaching';
import Owner from './owner/owner';
import { Helmet } from "react-helmet";

class Home extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Power of Ten | Professional Development for Educators and Parents</title>
          <meta name="description" content="Setting kids up for success in math and numeracyu through basic facts and math games. Power of Ten Educational Consulting is the best place for parents and educators alike to find resources for their students or children." />
        </Helmet>
        <Layout>
          <Hero />
          <Courses />
          <Games />
          <Teaching />
          <Owner />
        </Layout>
      </Fragment>
    )
  }
}

export default Home