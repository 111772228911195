import React, { Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./BookWorkshope.scss"

class Owner extends Component {
  render() {
    return (
      <Fragment>
        <div style={{ paddingTop: "100px" }} className="">

          <div className="container">

            <div className="columns is-variable is-4">

              <div className="column is-8">

                <div className="">
                  <h3 className="has-text-primary is-size-21 has-text-weight-bold mr-30px is-inline-block mb-30px">
                    Workshop Options and Formats
                  </h3>

                  <div className="mb-20px">
                    <div>
                      <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> Provincial-Based </span>
                      <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> District-Based </span>
                      <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> School-Based </span>
                      <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> Parent </span>
                      <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> Home Educators </span>
                    </div>
                    <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> English </span>
                    <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> Français </span><br />
                    <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> Full-Day </span>
                    <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> Half-Day </span>
                  </div>

                  <p className="mb-20px is-size-10">
                    Once your workshop goals have been discussed, a workshop will be designed to meet those needs.
                  </p>

                  <p className="mb-20px is-size-10">
                    Les ateliers Power of Ten sont offerts en français.  C’est en enseignant la plupart des domaines d’études le long de l’année que les élèves réussissent à vraiment comprendre les concepts et à approfondir leurs connaissances des concepts en mathématiques.  Veuillez me contacter afin de discuter de vos besoins.
                  </p>

                  <p className="has-text-primary is-size-10 has-text-weight-bold">
                    Students need to be able to look at a set of objects and know how many there are without counting.  This skill is called subitizing.
                  </p>
                </div>
                <div style={{ paddingTop: "100px" }} className="">

                  <div style={{paddingTop:"25px"}} className="">
                      <h3 className="has-text-primary is-size-21 has-text-weight-bold mr-30px is-inline-block mb-30px">
                      Supplemental Consulting Services
                      </h3>

                      <div className="mb-20px"> 
                        <div>
                          <span className="mr-15px is-inline-block is-size-10"><span className="has-text-primary has-text-weight-bold is-size-6">≥</span> Hourly Rate </span>
                        </div>
                      </div>
                      
                      <p className="mb-20px is-size-10">
                        Please contact Anita to discuss your consulting goals, which could include Professional Learning Community (PLC), specific objective (i.e. assessment, supporting struggling learners, challenging strong students), tutors, Educational Assistants, etc.
                      </p>
                      
                      <a style={{backgroundColor: "#F6303E", color: "#ffffff"}} href="mailto:anita@poweroften.ca?subject=Workshop Inquiry" className="button is-primary is-size-10 is-inverted radius-10px button-with-right-arrow red-icon mb-0">
                        Contact Anita
                      </a>
                  </div>
                </div>
                <div style={{ paddingTop: "100px" }} className="">
                  <h3 className="has-text-primary is-size-21 has-text-weight-bold mr-30px is-inline-block mb-30px">
                    Virtual Sessions with Anita
                  </h3>

                  <p className="mb-20px is-size-10">
                    Register here for the Power of Ten session of your choice.
                  </p>

                  <a style={{ backgroundColor: "#F6303E", color: "#ffffff" }} href="https://calendly.com/poweroftenca" className="button is-primary is-size-10 is-inverted radius-10px button-with-right-arrow red-icon mb-0">
                    Book Your Spot Now
                  </a>
                </div>
              </div>

              <div className="column is-5">

                <div className="p-50px has-background-primary box-shadow">

                  <h3 className="has-text-white is-size-20 has-text-weight-bold is-inline-block mb-40px">
                    Book a Workshop
                  </h3>

                  <p className="has-text-white mb-40px is-size-12">
                    Please contact Anita at anita<span className="at">@</span>poweroften.ca or (343) 549-6284 to inquire about your Power of Ten Workshop.
                  </p>

                  <a href="mailto:anita@poweroften.ca?subject=Workshop Inquiry" className="button is-primary is-size-10 is-inverted radius-10px button-with-right-arrow blue-icon mb-0">
                    Book Now
                  </a>

                </div>

              </div>

            </div>

          </div>

        </div>
      </Fragment>
    )
  }
}

export default Owner