import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Button } from 'react-bulma-components';
import "./ProductDetails.scss";
import { ReactComponent as BlueArrow } from "./images/blue_arrow.svg";
import UpArrow from "./images/up-arrow.png";
import { ReactComponent as FacebookIcon } from "./images/facebook_icon.svg";
import { ReactComponent as TwitterIcon } from "./images/twitter_icon.svg";
import { ReactComponent as PinterestIcon } from "./images/pinterest_icon.svg";
import Line from './images/line.png';
import BgGreyShapeImage from "./images/bg-grey-shape-image.png";
import { Parallax } from 'react-parallax';
import MyGlobleSetting from '../../../../MyGlobleSetting';
import { Collapse } from 'react-collapse';
import classNames from "classnames";
function createMarkup(string) {
  return { __html: string };
}
class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [], id: '', name: '', image: '', price: '', activeIndex: null };
    this.toggleClass = this.toggleClass.bind(this);
  }
  componentDidMount() {
    let path = window.location.pathname;
    let id = path.split('/');
    fetch(MyGlobleSetting.url + 'product/' + id[3])
      .then(response => response.json())
      .then(json => this.setState({ products: json, id: json[0].id, name: json[0].title, price: json[0].current_price, image: json[0].feature_image }));
  }
  componentWillReceiveProps(nextProps, nextState) {
    let path = window.location.pathname;
    let id = path.split('/');
    fetch(MyGlobleSetting.url + 'product/' + id[3])
      .then(response => response.json())
      .then(json => this.setState({ products: json, id: json[0].id, name: json[0].title, price: json[0].current_price, image: json[0].feature_image }));
  }
  toggleClass(index) {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index
    });
  }

  moreLess(index) {
    if (this.state.activeIndex === index) {
      return (
        <span>
          <img src={UpArrow} alt="" />
        </span>
      );
    } else {
      return (
        <span>
          <BlueArrow />
        </span>
      );
    }
  }



  static defaultProps = {
    modal: {},
  }
  state = {
    products: [], activeIndex: null
  }
  render() {
    const { products } = this.state;
    const { activeIndex } = this.state;
    return (
      Object.keys(products).map((val, k) =>
        <div k={k}>
          <Fragment>
            <div className="games-product-details-section py-100px" key={products[val].id}>

              <div className="container">
                <div className="text-right dash-line">
                  <img src={Line} alt="" className="top-line" />
                </div>
                <div className="">
                  <span className="is-size-8">Store / Games / {products[val].subcatname} / {products[val].title}</span>
                  <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">{products[val].title}</h3>
                </div>
                <div className="columns is-variable is-8">

                  <div className="column">

                    <div className="games-product-details-card-placeholder">
                      <img src={MyGlobleSetting.productimgurl + products[val].feature_image} alt="" className="" />
                    </div>

                  </div>

                  <div className="column is-half">

                    <h5 className="is-size-15 has-text-weight-bold has-text-primary mb-15px">LEARNING OUTCOMES</h5>

                    <span className="product-price-tag is-size-18 has-text-primary">
                      ${products[val].current_price}
                    </span>
                    <h5 className="is-size-19 has-text-weight-bold has-text-primary mb-15px">PRODUCT DESCRIPTION</h5>
                    <p className="mb-20px is-size-10 pr-50px" >
                      <div dangerouslySetInnerHTML={createMarkup(products[val].summary)} />
                    </p>
                    <Collapse isOpened={activeIndex === k}>
                      <div className={classNames("alert alert-info msg", {
                        show: activeIndex === k,
                        hide: activeIndex !== k
                      })} >
                        <p className="mb-20px is-size-10 pr-50px" >
                          <div dangerouslySetInnerHTML={createMarkup(products[val].description)} />
                        </p>
                      </div>
                    </Collapse>

                    <div className="mb-20px">
                      <a onClick={this.toggleClass.bind(this, k)} className={`${this.state.active ? 'is-active' : ''} has-text-primary is-size-10 has-text-weight-bold is-flex is-align-items-center read-more-link`}>
                        Read More {this.moreLess(k)}
                      
                      </a>
                      {/*<Link to="/" className="has-text-primary is-size-10 has-text-weight-bold is-flex is-align-items-center read-more-link">
                                          Read More
                                          <BlueArrow/>  
                                        </Link>*/}
                    </div>

                    {/* <Link to="/" className="button is-primary-red button-with-right-arrow radius-10px">Download</Link> */}

                    <div className="my-20px">
                      <Link to="/" className="has-text-primary is-size-11 has-text-weight-bold is-inline-flex is-align-items-center share-link">
                        Share
                      <FacebookIcon />
                        {/*<InstaIcon/>*/}
                        <TwitterIcon />
                        <PinterestIcon />
                        {/*<EmailIcon/>  */}
                      </Link>
                    </div>

                    <div className="mt-30px dash-line">
                      <img src={Line} alt="" className="" />
                    </div>


                  </div>

                </div>

              </div>

              <div className="games-product-details-bg-shape-image">
                <Parallax blur={0} bgImage={BgGreyShapeImage} bgImageAlt="Home Hero Background Image" strength={160}></Parallax>
              </div>

            </div>
          </Fragment>
        </div>
      )
    )
  }
}

export default ProductDetails