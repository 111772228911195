import React, { Fragment, useState, useEffect } from 'react';

import { Button } from 'react-bulma-components';
import { Link, useHistory, withRouter } from "react-router-dom";
import { ReactComponent as RightArrowIcon } from "./images/ic_left_arrow_blue.svg";
import { ReactComponent as LeftArrowIcon } from "./images/ic_left_arrow_blue.svg";
import "./ContactInformation.scss";
import Cookies from 'universal-cookie';
import MyGlobleSetting from '../../../MyGlobleSetting';
import SweetAlert from 'react-bootstrap-sweetalert';
import Logo from "../../../assets/images/logo.png";
import toast from 'toastr';
import { TransverseLoading } from 'react-loadingg';

const cookies = new Cookies();
//const stripeApiKey = "pk_test_51I6fhqGI0osNl9fAXwDoK2X9DJHWsqzCELDG4yvjcpC0gmBUHCAwHXrI4P1aM34u1bOk7APC5WSnz5NDvz5m4lIR00sDKzJFP6";
const stripeApiKey = "pk_live_51I6fhqGI0osNl9fAUhVPbzrXqjvyPGbsgh5CC2ergnw6gr75A338RCymdR9kS09LWhCLxF4zVhff7LHPLRWcEUdq00PPqHnstM";


let fname = React.createRef();
let lname = React.createRef();
let email = React.createRef();
let notifications = React.createRef();
let province = React.createRef();
let address = React.createRef();
let flat_num = React.createRef();
let city = React.createRef();
let postal_code = React.createRef();
let country = React.createRef();
let number = React.createRef();
let org = React.createRef();
let po_num = React.createRef();
let email1 = React.createRef();
let sub_total = React.createRef();
let total = React.createRef();





function ContactInformation() {
    const [data, setData] = useState([]),
        [page, setPage] = useState(0),
        [sum, setSum] = useState(0),
        [tax, setTax] = useState(0),
        [sub_total, setSubTotal] = useState(0),
        [total, setTotal] = useState(0),
        [taxamount, setTaxAmount] = useState(0),
        [shipping, setShipping] = useState(0),
        [show, setShow] = useState(false),
        [showLoading, setShowLoading] = useState(false),
        [shippingInt, setShippingInt] = useState(0),
        [shippingFinal, setShippingFinal] = useState(0),
        maxPage = data ? Math.ceil(data.length / 3) : 0,
        onNextPage = () => setPage((page + 1) % maxPage),
        onPrevPage = () => setPage((page + 3 - 1) % maxPage);

    const initialTutorialState = {
        fname: "",
        lname: "",
        email: "",
        number: "",
        org: "",
        city: "",
        postal_code: "",
        province: "",
        country: "Canada",
        address: "",
        email_verified: "Yes",
        status: "1",
        coupon: '',
        notifications: '',
        total: "",
        sub_total: "",
        discount: "",
        shipping: "",
        tax: "",
        province: "",
        po_num: "",
    };

    const [tutorial, setTutorial] = useState(initialTutorialState);
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        getShippingANDTax();
        fetchData();
    }, []);

    useEffect(() => {
        console.log(tax);
    }, [tax]);

    const history = useHistory()

    useEffect(() => {
        var getcook1 = cookies.get('userinfo');
        if (getcook1) {
            const data = JSON.parse(JSON.stringify(getcook1));

            tutorial.fname = data[0].fname;
            tutorial.lname = data[0].lname;
            tutorial.email = data[0].email;
            tutorial.number = data[0].number;
            tutorial.postal_code = data[0].postal_code;
            tutorial.city = data[0].city;
            tutorial.province = data[0].province;
            tutorial.country = data[0].country;
            tutorial.address = data[0].address;
            tutorial.notifications = data[0].notifications;
            tutorial.org = data[0].org;
            tutorial.po_num = data[0].po_num;
            setTaxByProvince();
        }
        //setTaxByProvince();
    }, []);

    useEffect(() => {
        if (tutorial.province == "Alberta" || tutorial.province == "British Columbia" || tutorial.province == "Manitoba" || tutorial.province == "Northwest Territories" || tutorial.province == "Nunavut" || tutorial.province == "Saskatchewan" || tutorial.province == "Yukon") {
            setTax(5);
        } else if (tutorial.province == "Ontario") {
            setTax(13);
        } else if (tutorial.province == "New Brunswick" || tutorial.province == "Newfoundland and Labrador" || tutorial.province == "Prince Edward Island" || tutorial.province == "Nova Scotia") {
            setTax(15);
        } else if (tutorial.province == "Quebec") {
            setTax(14.975);
        }
    }, [tutorial.province]);


    const setTaxByProvince = () => {
        console.log("setTaxByProvince" + tutorial.province);
        if (tutorial.province == "Alberta" || tutorial.province == "British Columbia" || tutorial.province == "Manitoba" || tutorial.province == "Northwest Territories" || tutorial.province == "Nunavut" || tutorial.province == "Saskatchewan" || tutorial.province == "Yukon") {
            setTax(5);
        } else if (tutorial.province == "Ontario") {
            setTax(13);
        } else if (tutorial.province == "New Brunswick" || tutorial.province == "Newfoundland and Labrador" || tutorial.province == "Prince Edward Island" || tutorial.province == "Nova Scotia") {
            setTax(15);
        } else if (tutorial.province == "Quebec") {
            setTax(14.975);
        }
    }

    async function getShippingANDTax() {
        fetch(MyGlobleSetting.url + 'get-tax',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data && data.length) {
                    var json = data[0];
                    //setTax(json.tax_charges);
                    setTaxByProvince();
                    //setShipping(json.shipping_charges);
                    setShippingInt(json.international_shipping_charges);
                    //setShippingFinal(json.shipping_charges);
                    console.log("Tax: " + tax)
                    var taxamount = ((sum - discount) * tax) / 100;
                    setSubTotal(sum);
                    setTaxAmount(taxamount);
                    setTotal(((sum - discount) + shippingFinal + (((sum - discount) * tax) / 100)).toFixed(2));

                }
            })
            .catch(e => {
                console.log(e);
            });
    }
    async function fetchData() {
        const response = await cookies.get('products');
        const noShipping = [173, 174, 42, 41, 40, 39, 178, 177, 175, 176];
        const manualShipping = [43, 44, 46, 47, 48, 49, 184, 183, 182, 181, 180, 179, 17];
        const parentKitShipping = [18];
        var shipping = 0;
        for (var i = 0; i < response.length; i++) {
            if (noShipping.includes(response[i].id)) {
                if (shipping <= 0) {
                    shipping = 0;
                }
            } else if (manualShipping.includes(response[i].id)) {
                if (shipping <= 10) {
                    shipping = 10;
                }
            } else if (parentKitShipping.includes(response[i].id)) {
                if (shipping <= 4) {
                    shipping = 4;
                }
            } else {
                shipping = 23;
            }
        }
        setShipping(shipping);
        setShippingFinal(shipping);
        console.log("Shipping:" + shipping);
        setData(response);
        if (response)
            setSum(response.reduce(function (prev, current) { return prev + +(current.price * current.qty) }, 0));
    }
    //Store User Information Start
    const handleInputChange = event => {
        const { name, value } = event.target;
        setTutorial({ ...tutorial, [name]: value });
        console.log(tutorial);
    };

    const applyCoupon = () => {
        const { coupon } = tutorial
        fetch(MyGlobleSetting.url + 'check-coupon/' + coupon,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })

            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log(data);
                if (data && data.length) {
                    const { type, amount } = data[0];
                    const disc = (!Number(type)) ? amount : (sum * amount) / 100;
                    setDiscount(disc);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    const createUser = () => {

        return new Promise((resolve, reject) => {
            var data = {
                fname: email.current.fname,
                lname: email.current.lname,
                email: email.current.value,
                number: email.current.number,
                city: email.current.city,
                state: email.current.province,
                country: email.current.country,
                address: email.current.address,
                email_verified: email.current.email_verified,
                status: email.current.status,
                notifications: email.current.notifications,
            };
            fetch(MyGlobleSetting.url + 'user-post',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        fname: data.fname,
                        lname: data.lname,
                        email: data.email,
                        number: data.number,
                        city: data.city,
                        state: data.province,
                        country: data.country,
                        address: data.address,

                    })
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    resolve(data);
                })
                .catch(e => {
                    reject(e);
                });
        })
    }



    const placeOrder = (userid, id = '', token) => {
        return new Promise(async (resolve, reject) => {
            await fetchData();
            await getShippingANDTax();
            setTimeout(() => {
                var product = {
                    user: userid,
                    billing_fname: tutorial.fname,
                    billing_lname: tutorial.lname,
                    billing_email: tutorial.email,
                    billing_number: tutorial.number,
                    billing_city: tutorial.city,
                    billing_state: tutorial.province,
                    billing_country: tutorial.province + " CANADA",
                    billing_address: tutorial.org + " " + tutorial.address + " " + tutorial.postal_code,
                    po_num: tutorial.po_num,
                    total: document.getElementById("total").value,
                    shipping: document.getElementById("shipping_charges").value,
                    tax: document.getElementById("taxes").value,
                    sub_total: document.getElementById("sub_total").value,
                    discount: document.getElementById("discounts").value,
                    txnid: id || '',
                    notifications: tutorial.notifications,
                    products: JSON.stringify(cookies.get('products'))
                };
                // console.log(JSON.stringify(product.products));
                if (tutorial.notifications === 'yes') {
                    fetch("https://api.sendgrid.com/v3/marketing/contacts", {
                        method: "PUT",
                        headers: {
                            "Authorization":
                                "Bearer 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",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            'list_ids': [
                                '0e00ba28-0261-4535-8f88-353f437a3c44'
                            ],
                            'contacts': [
                                {
                                    'email': tutorial.email,
                                    'first_name': tutorial.fname,
                                    'last_name': tutorial.lname
                                }
                            ]
                        })
                    })
                }
                fetch(MyGlobleSetting.url + 'product-post',
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(product)
                    }).then(function (response) {
                        return response.json();
                    }).then(function (data) {
                        console.log(data);
                        setTutorial(initialTutorialState);
                        fetchData();
                        resolve(data);
                        setShow(true);
                        cookies.remove('products');
                        cookies.remove('userinfo');
                    }).catch(e => {
                        console.log(e);
                        reject(e);
                    });
            }, 1500);
        });
    }
    const queryParams = new URLSearchParams(window.location.search);

    const saveTutorial = async () => {
        const user = await createUser();
        if (user && user.success) {
            const response = await placeOrder(user.last_insert_id)
            toast.success(response);
        }
    };


    const handleClose = () => { console.log("App#handleClose"); };
    const handleOpen = () => { console.log("App#handleOpen"); };
    const handleToken = async (e) => {
        cookies.set('userinfo', [{ email: email.current.value, fname: fname.current.value, lname: lname.current.value, org: tutorial.org, address: address.current.value, city: city.current.value, country: country.current.value, number: number.current.value, postal_code: postal_code.current.value, notifications: tutorial.notifications, province: tutorial.province, po_num: tutorial.po_num }], { path: '/' });
        document.getElementById("stripeForm").submit();

    }
    const changeShipping = (event) => {
        tutorial.country = event.target.value;
        if (event.target.value == "Canada") {
            setShippingFinal(shipping);
        } else {
            setShippingFinal(shippingInt);
        }
    }
    const ReceiveNotification = (event) => {
        if (event.target.checked) {
            tutorial.notifications = 'yes';
        } else {
            tutorial.notifications = 'no';
        }
    }

    useEffect(() => {
        if (history && history.location && history?.location?.search?.includes('success=true')) {
            setShowLoading(true);
            const callCreateUser = async () => {
                const user = await createUser();
                if (user && user.success) {
                    const response = await placeOrder(user.last_insert_id, "124578")
                    setShowLoading(false);
                    toast.success(response);
                }
            }
            callCreateUser();
        }
    }, [history]);

    useEffect(() => {
        console.log(tutorial)
    }, [tutorial]);

    return (
        <Fragment>
            {showLoading == true ? (<div>
                <h1 className="loadingText">Processing. Do not close this window.</h1>
                <TransverseLoading style={{ margin: 'auto', position: 'absolute', inset: '0px', zIndex: '1000', width: 'auto', height: '100vw', background: 'rgba(0,0,0,0.5)', padding: '0 20%' }} />
            </div>) : ""}
            <div className=" py-100px">
                <div style={{ background: '#1682C5', borderRadius: '20px', width: 'fit-content', padding: '24px', maxWidth: '1344px', margin: '0 auto 32px' }}>
                    <h2 style={{ color: '#fff', fontSize: '20px' }}>Welcome to our new store. If you have any issues please contact us at anita@poweroften.ca</h2>
                </div>
                <div className="container">
                    <div className="">
                        <span className="is-size-8">Information > Shipping > Payment</span>
                        <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">Contact Information</h3>
                    </div>
                    <div className="">
                        <div className="columns is-variable is-8">
                            <div className="column is-three-fifths">
                                <div className="mb-60px">
                                    <input type="hidden" id="discounts" name="discount" value={discount.toFixed(2)} />
                                    <input type="hidden" id="shipping_charges" name="shipping" value={shippingFinal.toFixed(2)} />
                                    <input type="hidden" id="sub_total" name="sub_total" value={sum.toFixed(2)} />
                                    <input type="hidden" id="taxes" name="tax" value={(((sum.toFixed(2) - discount.toFixed(2)) * tax.toFixed(2)) / 100).toFixed(2)} />
                                    <input type="hidden" id="total" name="total" value={((sum - discount) + shippingFinal + (((sum - discount) * tax) / 100)).toFixed(2)} />

                                    <div class="field default-form-group mb-20px">
                                        <div class="control">
                                            <input class="input" ref={email} type="email" name="email" value={tutorial.email} placeholder="EMAIL *" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div class="field form-group">
                                        <div class="control checkbox-control">
                                            <input type="checkbox" ref={notifications} value="yes" id="notifications" name="notifications" onChange={ReceiveNotification} />
                                            <label class="checkbox-label is-uppercase" for="notifications">Receive updates &amp; subscribe to newsletter</label>
                                        </div>
                                    </div>
                                    <div class="field default-form-group">
                                        <div class="control">
                                            <input class="input" ref={po_num} name="po_num" id="po_num" value={tutorial.po_num} onChange={handleInputChange} type="text" placeholder="PURCHASE ORDER NUMBER" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                                <h3 className="owner-heading is-size-3 has-text-weight-bold mt-10px mb-30px">Shipping Address</h3>
                                <div className="mb-60px">
                                    <div className="columns is-variable is-4">
                                        <div className="column">
                                            <div class="field default-form-group">
                                                <div class="control">
                                                    <input class="input" ref={fname} name="fname" id="fname" value={tutorial.fname} onChange={handleInputChange} type="text" placeholder="FIRST NAME *" autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div class="field default-form-group">
                                                <div class="control">
                                                    <input class="input" ref={lname} name="lname" id="lname" value={tutorial.lname} onChange={handleInputChange} type="text" placeholder="LAST NAME *" autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field default-form-group">
                                        <div class="control">
                                            <input class="input" ref={org} name="org" id="org" value={tutorial.org} onChange={handleInputChange} type="text" placeholder="SCHOOL OR ORGANIZATION" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div class="field default-form-group">
                                        <div class="control">
                                            <input class="input" ref={address} name="address" id="address" value={tutorial.address} onChange={handleInputChange} type="text" placeholder="ADDRESS *" autoComplete="off" />
                                        </div>
                                    </div>
                                    {/* <div class="field default-form-group">
                                        <div class="control">
                                            <input class="input" ref={flat_num} name="flat_num" id="flat_num" value={tutorial.flat_num} onChange={handleInputChange} type="text" placeholder="APARTMENT/SUITE/ETC (OPTIONAL)" autoComplete="off" />
                                        </div>
                                    </div> */}
                                    <div class="field default-form-group">
                                        <div class="control">
                                            <input class="input" ref={city} name="city" id="city" value={tutorial.city} onChange={handleInputChange} type="text" placeholder="CITY *" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div class="field default-form-group">
                                        <div class="control">
                                            <input class="input" ref={postal_code} name="postal_code" id="postal_code" value={tutorial.postal_code} onChange={handleInputChange} type="text" placeholder="POSTAL CODE *" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div class="field default-form-group">
                                        <div class="control" style={{ 'border-bottom': '1px solid #636363' }}>
                                            <select ref={province} value={tutorial.province} name="province" id="province" onChange={handleInputChange} class="input">
                                                <option value="" value="" disabled selected>PROVINCE *</option>
                                                <option value="Alberta">Alberta</option>
                                                <option value="British Columbia">British Columbia</option>
                                                <option value="Manitoba">Manitoba</option>
                                                <option value="New Brunswick">New Brunswick</option>
                                                <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                                <option value="Northwest Territories">Northwest Territories</option>
                                                <option value="Nova Scotia">Nova Scotia</option>
                                                <option value="Nunavut">Nunavut</option>
                                                <option value="Ontario">Ontario</option>
                                                <option value="Prince Edward Island">Prince Edward Island</option>
                                                <option value="Quebec">Quebec</option>
                                                <option value="Saskatchewan">Saskatchewan</option>
                                                <option value="Yukon">Yukon</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="field default-form-group">
                                        <div class="control" style={{ 'border-bottom': '1px solid #636363' }}>
                                            <select ref={country} value={tutorial.country} name="country" id="country" onChange={changeShipping} class="input">
                                                {/* <option value="">Country</option> */}
                                                <option value="Canada">Canada</option>
                                                {/* <option value="USA">USA</option>
                                                <option value="Other">Other</option> */}
                                                <option disabled="true" value="Please Contact">Please contact us directly for orders to other countries.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="field default-form-group">
                                        <div class="control">
                                            <input class="input" name="email" id="email" value={tutorial.email} onChange={handleInputChange} type="email" placeholder="EMAIL *" autoComplete="off" />
                                        </div>
                                    </div>
                                    <div class="field default-form-group mb-30px">
                                        <div class="control">
                                            <input class="input" ref={number} name="number" id="number" value={tutorial.number} onChange={handleInputChange} type="text" placeholder="PHONE *" autoComplete="off" />
                                        </div>
                                    </div>
                                    {/* <div class="field form-group">
                                        <div class="control checkbox-control">
                                            <input type="checkbox" id="save-info" />
                                            <label class="checkbox-label is-uppercase" for="save-info">Save Information For next Time</label>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="is-flex is-align-items-center is-justify-content-space-between">
                                    <Link to="/store" className="has-text-primary is-uppercase">Back to Store</Link>
                                </div>

                            </div>
                            <div className="column">
                                <div className="checkout-shopping-cart-box">
                                    <div className="px-30px py-50px">

                                        <div className="is-flex is-align-items-center is-justify-content-space-between mb-20px">
                                            <h5 className="has-text-weight-bold is-size-3">Shopping Cart</h5>
                                        </div>

                                        <div className="checkout-shopping-cart-list complete-set-list">
                                            {data && data.slice(page * 3, 3 * (page + 1)).map((content, key) => (
                                                <div className="checkout-shopping-cart-item" key={content.id + key}>
                                                    <div className="">
                                                        <img src={MyGlobleSetting.productimgurl + content.image} alt="" className="checkout-shopping-cart-item-image" />
                                                    </div>
                                                    <div className="is-flex is-align-items-flex-start is-justify-content-center is-flex-direction-column">
                                                        <h6 className="has-text-weight-bold">{content.name}</h6>
                                                        <span className="has-text-primary"> Unit Price : {content.price}</span>
                                                        <span className="has-text-primary"> Quantity : {content.qty}</span>

                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>

                                        <div className="columns is-variable is-2">
                                            <div className="column">
                                                <div class="field default-form-group">
                                                    {!discount && <div class="control">
                                                        <input class="input" type="text" placeholder="PROMO CODE" name="coupon" value={tutorial.coupon} onChange={handleInputChange} />
                                                        <button className="button is-primary is-small" onClick={applyCoupon} disabled={!tutorial.coupon}>
                                                            Apply
                                                        </button>
                                                    </div>}
                                                    {discount > 0 && <div class="control">
                                                        <label className="has-text-success text-bold">{tutorial.coupon}</label>
                                                        <button className="button is-danger is-small" onClick={() => { setDiscount(0); setTutorial({ ...tutorial, coupon: '' }); }}>
                                                            Remove
                                                        </button>
                                                    </div>}

                                                </div>
                                            </div>
                                            {data.length > 3 ?
                                                <div className="column is-flex is-align-items-center is-justify-content-flex-end">
                                                    <ul className="checkout-shopping-cart-pagination">
                                                        <li>
                                                            <Link onClick={onPrevPage} disabled={!page}>
                                                                <span className="pagination-icon prev">  <LeftArrowIcon />   </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={onNextPage} disabled={page == data ? Math.ceil(data.length / 3) - 1 : 0}>
                                                                <span className="pagination-icon next">  <RightArrowIcon />   </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="p-30px has-background-light">
                                        <div className="columns is-mobile mb-0">
                                            <div className="column">
                                                <span className="is-size-8">Subtotal</span>
                                            </div>
                                            <div className="column has-text-right">
                                                <span className="is-size-8">${sum.toFixed(2)}</span>
                                            </div>
                                        </div>
                                        {discount > 0 &&
                                            <div className="columns is-mobile mb-0">
                                                <div className="column">
                                                    <span className="is-size-8">Discount</span>
                                                </div>
                                                <div className="column has-text-right">
                                                    <span className="is-size-8">${discount.toFixed(2)}</span>
                                                </div>
                                            </div>
                                        }
                                        <div className="columns is-mobile mb-0">
                                            <div className="column">
                                                <span className="is-size-8">Shipping</span>
                                            </div>
                                            <div className="column has-text-right">
                                                <span className="is-size-8">${shippingFinal.toFixed(2)}</span>
                                            </div>
                                        </div>

                                        <div className="columns is-mobile mb-0">
                                            <div className="column">
                                                <span className="is-size-8">Taxes({tax.toFixed(2)}%)</span>
                                            </div>
                                            <div className="column has-text-right">
                                                <span className="is-size-8">${(((sum.toFixed(2) - discount.toFixed(2)) * tax.toFixed(2)) / 100).toFixed(2)}</span>
                                            </div>
                                        </div>

                                        <div className="columns is-mobile mb-0">
                                            <div className="column">
                                                <span className="is-size-22 has-text-weight-bold">TOTAL</span>
                                            </div>
                                            <div className="column has-text-right">
                                                <span className="is-size-22 has-text-weight-bold">${((sum - discount) + shippingFinal + (((sum - discount) * tax) / 100)).toFixed(2)}</span>
                                            </div>
                                        </div>
                                        <div className="">
                                            {sum.toFixed(2) > 0 &&
                                                <form id="stripeForm" action="https://api.poweroften.ca/cp/assets/stripe/demo.php" method="POST">
                                                    <input type="hidden" name="price" value={((sum - discount) + shippingFinal + (((sum - discount) * tax) / 100)).toFixed(2)} />
                                                    <button onClick={handleToken} type="button" className="button is-primary" disabled={!(tutorial.fname && tutorial.lname && tutorial.email && tutorial.number && tutorial.city && tutorial.address && tutorial.postal_code && tutorial.province)}>
                                                        Pay Now
                                                    </button>
                                                </form>
                                            }
                                            {sum.toFixed(2) == 0 &&
                                                <Button onClick={saveTutorial} type="button" className="button is-primary button-with-right-arrow" disabled={!(tutorial.fname && tutorial.lname && tutorial.email && tutorial.number && tutorial.city && tutorial.address && tutorial.postal_code && tutorial.province)}>
                                                    <span className=""> Pay Now</span>
                                                </Button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SweetAlert show={show} success title="Success!" onConfirm={() => { setShow(false); window.location.href = 'https://poweroften.ca'; }} onEscapeKey={() => { setShow(false); window.location.href = 'https://poweroften.ca'; }}
                    onOutsideClick={() => { setShow(false); window.location.href = 'https://poweroften.ca'; }}>
                    Order Placed successfully. Check your email for a confirmation!
                </SweetAlert>
            </div>
        </Fragment>
    );
}
export default withRouter(ContactInformation)