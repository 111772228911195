import React from 'react';
import Header from '../component/header/header';
import Footer from '../component/footer/footer';

function Layout({ children, NotFound }) {
    return (
        <React.Fragment>
            <Header PageNotFound={NotFound} />
                <div>
                    {children}
                </div>
            <Footer />
        </React.Fragment>
    );
}

export default Layout;