import React, { Component, Fragment } from 'react'
import '../../App.scss';
import Layout from '../layout/layout';
import Hero from './hero/hero';
import BookWorkshope from './book-workshope/BookWorkshope';
import DownloadPackage from './download-package/DownloadPackage';
import CancellationPolicy from './cancellation-policy/CancellationPolicy';
import { Helmet } from "react-helmet";

class Workshops extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Workshops | Power of Ten</title>
          <meta name="description" content="Online and in-person professional workshops for professional development of educators. Boost confidence in your curriculum and your abilities through professional development workshops." />
        </Helmet>
        <Layout>
          <Hero />
          <BookWorkshope />
          <CancellationPolicy />
        </Layout>
      </Fragment>
    )
  }
}

export default Workshops