import React, {Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import "./GettingStartedMenu.scss"

class GettingStartedMenu extends Component {

    render() {
        
        return (
        
        <Fragment>

            <div className="bg-gray-light py-25px">

                <div className="container">

                    <div className="store-page-category-menu">
                        <ul>
                            <li><Link to="#">FEATURED</Link></li>
                            <li><Link to="#">POWER OF TEN</Link></li>
                            <li><Link to="#">LESSONS</Link></li>
                            <li><Link to="#" className="active">GAME</Link></li>
                            <li><Link to="#">FREEBIES</Link></li>
                        </ul>
                    </div>
                   
                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default GettingStartedMenu