import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

function DisplayPDF({ file }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error) => alert('Error while loading document! ' + error.message)}
                className="pdfcontainer"
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <div style={{ display: 'flex', alignItems: 'center', margin: '0 auto', width: 'fit-content' }}>
                <button onClick={() => setPageNumber(pageNumber - 1)}>
                    Previous Page
                </button>
                <p style={{ margin: '8px' }}>Page {pageNumber} of {numPages}</p>
                <button onClick={() => setPageNumber(pageNumber + 1)}>
                    Next Page
                </button>
            </div>

        </div >
    );
}

export default DisplayPDF;