import React, { Component, Fragment } from "react";
// import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Button } from "react-bulma-components";
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom";
import "./FreebieDetails.scss";
import FreebieCardPlaceholder from "./images/freebie-card-placeholder.png";
import { ReactComponent as BlueArrow } from "./images/blue_arrow.svg";
import { ReactComponent as FacebookIcon } from "./images/facebook_icon.svg";
import { ReactComponent as InstaIcon } from "./images/insta_icon.svg";
import { ReactComponent as TwitterIcon } from "./images/twitter_icon.svg";
import { ReactComponent as PinterestIcon } from "./images/pinterest_icon.svg";
import { ReactComponent as EmailIcon } from "./images/email_icon.svg";
import Modal from "react-bulma-components/lib/components/modal";
import Line from "./images/line.png";
import BgGreyShapeImage from "./images/bg-grey-shape-image.png";
import { Parallax } from "react-parallax";
import Cookies from "universal-cookie";
import UpArrow from "./images/up-arrow.png";
import { Collapse } from "react-collapse";
import classNames from "classnames";
import MyGlobleSetting from "../../../../MyGlobleSetting";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
const cookies = new Cookies();

function createMarkup(string) {
  return { __html: string };
}
const validationObject = Yup.object().shape({
  name: Yup.string().min(2, "The title needs to be at least 2 char").required(),
  email: Yup.string().email().required(),
});
const initialValues = {
  name: "",
  email: "",
};
class FreebieDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [], activeIndex: null, show: false, id: "", showModal: false };
    this.toggleClass = this.toggleClass.bind(this);
  }
  componentDidMount() {
    let path = window.location.pathname;
    let id = path.split("/");
    fetch(MyGlobleSetting.url + "product/" + id[3])
      .then((response) => response.json())
      .then((json) => this.setState({ products: json }));
    this.setState({
      id: id[3],
      notifications: "no",
    });
  }
  componentWillReceiveProps(nextProps, nextState) {
    let path = window.location.pathname;
    let id = path.split("/");
    fetch(MyGlobleSetting.url + "product/" + id[3])
      .then((response) => response.json())
      .then((json) => this.setState({ products: json }));
    this.setState({
      id: id[3],
    });
  }
  toggleClass(index, e) {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index,
    });
  }
  downloadFile = () => {
    //window.location.href = MyGlobleSetting.url1 + "assets/K-3-Workshop-and-Conference-Handout-Package-2016-2017.pdf"
    //window.location.href =  "https://socialsquared.dev/cp/assets/K-3-Workshop-and-Conference-Handout-Package-2016-2017.pdf"
  };
  moreLess(index) {
    if (this.state.activeIndex === index) {
      return (
        <span>
          <img src={UpArrow} alt="" />
        </span>
      );
    } else {
      return (
        <span>
          <BlueArrow />
        </span>
      );
    }
  }
  static defaultProps = {
    modal: {},
  };
  state = {
    products: [],
    activeIndex: null,
  };

  open = (i) => this.setState({ show: true, selectedPost: i });

  close = () => this.setState({ show: false });

  receiveNotification = (event) => {
    if (event.target.checked) {
      this.state.notifications = 'yes';
    } else {
      this.state.notifications = 'no';
    }
  }

  render() {
    const { products, show, activeIndex } = this.state;

    console.log(this.state)
    console.log(this.state.id)

    return Object.keys(products).map((val, k) => (
      <div k={k} key={products[val].id}>
        <Fragment>
          <div
            className="freebie-details-section py-100px"
            key={products[val].id}>
            <div className="container">
              <div className="text-right dash-line">
                <img src={Line} alt="" className="top-line" />
              </div>

              <div className="">
                <span className="is-size-8">
                  Store / Freebies / {products[val].title}
                </span>
                <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">
                  {products[val].title}
                </h3>
              </div>

              <div className="columns is-variable is-8">
                <div className="column">
                  <div className="freebie-card-placeholder">
                    <img
                      src={
                        MyGlobleSetting.productimgurl +
                        products[val].feature_image
                      }
                      alt=""
                      className=""
                    />
                  </div>
                </div>

                <div className="column is-half">
                  <span className="product-price-tag is-size-18 has-text-primary">
                    ${products[val].current_price}
                  </span>
                  <h5 className="is-size-19 has-text-weight-bold has-text-primary mb-15px">
                    PRODUCT DESCRIPTION
                  </h5>
                  <p className="mb-20px is-size-10 pr-50px">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        products[val].summary
                      )}
                    />
                  </p>
                  <Collapse isOpened={activeIndex === k}>
                    <div
                      className={classNames("alert alert-info msg", {
                        show: activeIndex === k,
                        hide: activeIndex !== k,
                      })}>
                      <p className="mb-20px is-size-10 pr-50px">
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            products[val].description
                          )}
                        />
                      </p>
                    </div>
                  </Collapse>

                  <div className="mb-20px">
                    <a
                      onClick={this.toggleClass.bind(this, k)}
                      className={`${this.state.active ? "is-active" : ""
                        } has-text-primary is-size-10 has-text-weight-bold is-flex is-align-items-center read-more-link`}>
                      Read More {this.moreLess(k)}
                    </a>
                    {/*<Link to="/" className="has-text-primary is-size-10 has-text-weight-bold is-flex is-align-items-center read-more-link">
                                          Read More
                                          <BlueArrow/>  
                                        </Link>*/}
                  </div>

                  {this.state.id === '172' ?
                    (<a
                      href="https://api.poweroften.ca/resources/orderform.pdf"
                      className="button is-primary-red is-size-10 button-with-right-arrow radius-10px">
                      Download
                    </a>)
                    : (<a
                      onClick={() => this.open()}
                      className="button is-primary-red is-size-10 button-with-right-arrow radius-10px">
                      Download
                    </a>)}
                  {/* <a
                    onClick={() => this.open()}
                    className="button is-primary-red is-size-10 button-with-right-arrow radius-10px">
                    Download
                  </a> */}

                  <div className="my-20px">
                    <Link
                      to="/"
                      className="has-text-primary is-size-10 has-text-weight-bold is-inline-flex is-align-items-center share-link">
                      Share
                      <FacebookIcon />
                      {/*<InstaIcon/>  */}
                      <TwitterIcon />
                      <PinterestIcon />
                      {/*<EmailIcon/>  */}
                    </Link>
                  </div>

                  <div className="mt-30px dash-line">
                    <img src={Line} alt="" className="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="freebie-details-bg-shape-image">
              <Parallax
                blur={0}
                bgImage={BgGreyShapeImage}
                bgImageAlt="Home Hero Background Image"
                strength={160}></Parallax>
            </div>
          </div>

          <Modal
            className="modal"
            show={this.state.show}
            onClose={this.close}
            {...this.props.modal}
            modal={{ closeOnBlur: true, showClose: false }}>
            <div class="modal-background"></div>
            <div class="modal-card modal-card-lg">
              <section class="modal-card-body p-40px">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationObject}
                  onSubmit={(values, { resetForm }) => {
                    let that = this;

                    let path = window.location.pathname;
                    let id = path.split("/");
                    const obj = {
                      ...values,
                      email: values.email,
                      product_id: id && id.length ? id[3] || 0 : 0,
                      name: values.name,
                    };
                    if (this.state.notifications === 'yes') {
                      fetch("https://api.sendgrid.com/v3/marketing/contacts", {
                        method: "PUT",
                        headers: {
                          "Authorization":
                            "Bearer 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",
                          "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                          'list_ids': [
                            '0e00ba28-0261-4535-8f88-353f437a3c44'
                          ],
                          'contacts': [
                            {
                              'email': obj.email,
                              'first_name': obj.name,
                            }
                          ]
                        })
                      })
                    }
                    fetch(MyGlobleSetting.url + "freebies-download", {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(obj),
                    })
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (data) {
                        console.log(data);
                        resetForm({});
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                    this.close();
                    this.setState({ showModal: true });
                  }}>
                  {({ values, handleChange, handleBlur }) => (
                    <Form>
                      <input
                        class="input"
                        type="hidden"
                        placeholder="productID"
                        name="productID"
                        value={this.state.id}
                      />
                      <div className="columns">
                        <div className="column">
                          <h2 className="modalHeading">
                            Enter your email to receive your free download:
                          </h2>
                          <div class="field form-group mb-20px">
                            <div class="control">
                              <input
                                class="input"
                                type="text"
                                placeholder="NAME"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <ErrorMessage
                              className="has-text-danger"
                              name="name"
                              component="div"
                            />
                          </div>

                          <div class="field form-group" style={{ marginBottom: '0' }}>
                            <div class="control">
                              <input
                                class="input"
                                type="text"
                                placeholder="EMAIL"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <ErrorMessage
                              className="has-text-danger"
                              name="email"
                              component="div"
                            />
                          </div>
                          <div class="control checkbox-control" style={{ display: 'flex', alignItems: 'baseline', marginBottom: '10px' }}>
                            <input type="checkbox" value="yes" id="notifications" name="notifications" onChange={this.receiveNotification} />
                            <label class="checkbox-label is-uppercase" for="notifications" style={{ marginLeft: '4px' }}>Receive updates &amp; subscribe to newsletter</label>
                          </div>
                          <div className="columns">
                            <div className="column is-flex is-align-items-center">
                              <button class="button is-primary button-with-right-arrow mb-0">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </section>
            </div>
          </Modal>
          <SweetAlert
            show={this.state.showModal}
            success
            title="Success!"
            onConfirm={() => {
              this.setState({ showModal: false });
            }}
            onEscapeKey={() => {
              this.setState({ showModal: false });
            }}
            onOutsideClick={() => {
              this.setState({ showModal: false });
            }}>
            Free Download Sent. Check your email!
          </SweetAlert>
        </Fragment>
      </div>
    ));
  }
}

export default FreebieDetails;
