import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import Slider from "react-slick"
// import SliderBgImage1 from "./images/cards1.png"
// import SliderBgImage2 from "./images/cards2.png"
// import SliderBgImage3 from "./images/cards3.png"
import MyGlobleSetting from '../../../../MyGlobleSetting';
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import "./Cards.scss"

class Cards extends Component {

    constructor(props) {
       super(props);
       this.state = { products: [] };
     }
     substr1(str){
      return str.substr(0, 80)+"...";
    }
     componentDidMount(){
      fetch(MyGlobleSetting.url + 'products/7')
      .then(response => response.json())
      .then(json => this.setState({ products: json.data }));

      }
    static defaultProps = {
        modal: {},
    }
    state = {
      products:[]
    }
    render() {
        
        const { products } = this.state;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    arrows: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                  }
                }
              ]
          };

          console.log(products)

        return (
        
        <Fragment>

            <div className="full-free-collection-section py-100px">

                <div className="container" id="cards">

                    <div className="is-flex-tablet is-align-items-center mb-30px">
                        <h3 className="is-size-20-mobile is-size-24-tablet has-text-weight-bold my-0 mr-30px">Power of Ten Cards</h3>
                        <Link to="store/shop-all-cards/7" className="button is-primary button-with-right-arrow is-size-10 radius-10px mb-0">Shop All Cards</Link>
                    </div>

                    <div>
                    
                    <Slider {...settings}>
                        {
                          products.map((product) => (
                            <div className="slider-item-box" key="{product.id}">
                              <Link to={`/store/product-details/${product.id}`} className="slider-link-button">
                                <div className="slider-item">
                                    <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image"/>
                                    <div className="slider-item-caption">
                                        <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                        <p className="is-size-7 mb-15px">{this.substr1(product.summary)}</p>
                                        <Button className="button is-small is-primary is-size-3 is-rounded mb-0">Learn More</Button>
                                            {/*<span className="slider-link-button-inner-text"> Learn More </span>
                                            <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>*/}
  
                                    </div>
                                </div>
                                </Link>
                            </div>
                          ))
                        }
                      </Slider>
                    
                    </div>

                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default Cards