import React, { Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
// import Slider from "react-slick"
import Line from './images/line.png'
import { ReactComponent as LightbulbIcon } from "./images/ic_lightbulb.svg"
import MyGlobleSetting from '../../../../MyGlobleSetting';
import "./InteractiveLessons.scss"
import { HashLink } from 'react-router-hash-link';

class InteractiveLessons extends Component {
    constructor(props) {
        super(props);
        this.state = { products: [], featuredproducts: [] };
    }
    componentDidMount() {
        fetch(MyGlobleSetting.url + 'products/11')
            .then(response => response.json())
            .then(json => this.setState({ products: json.data }));
    }
    static defaultProps = {
        modal: {},
    }
    state = {
        products: [],
        featuredproducts: []

    }
    render() {
        const { products } = this.state;
        return (

            <Fragment>

                <div className="interactive-lessons-section py-60px" id="interactiveLessions">

                    <div className="text-right mb-40px dash-line">
                        <img src={Line} alt="" className="" />
                    </div>

                    <div className="container">

                        <div className="is-flex-tablet is-align-items-center mb-30px" ref={this.myDivToFocus1}>

                            <h3 className="is-size-20-mobile is-size-24-tablet has-text-weight-bold my-0 mr-30px">Power of Ten Manuals</h3>
                            <Link to="store/shop-all-lesson/11" className="button is-primary button-with-right-arrow is-size-10 is-size-6-mobile radius-10px mb-0">Shop All Manuals</Link>
                        </div>

                        <HashLink to="/store/shop-all-lesson/11#cfdp" className="button is-primary button-with-right-arrow is-size-10 is-size-6-mobile radius-10px mb-5" style={{ whiteSpace: 'normal', height: 'auto', marginRight: '24px' }}>
                            Connecting Fractions, Decimals and Percentages Manuals
                        </HashLink>

                        <HashLink to="/store/shop-all-lesson/11#mtb" className="button is-primary button-with-right-arrow is-size-10 is-size-6-mobile radius-10px mb-5" style={{ marginRight: '24px' }}>

                            Mastering the Basics Manuals
                        </HashLink>

                        <HashLink to="/store/shop-all-lesson/11#tmm" className="button is-primary button-with-right-arrow is-size-10 is-size-6-mobile radius-10px mb-0">
                            Teachable Moments Manuals
                        </HashLink>

                    </div>

                    <div className="text-left mt-20px dash-line">
                        <img src={Line} alt="" className="" />
                    </div>

                </div>

            </Fragment>

        )

    }

}

export default InteractiveLessons