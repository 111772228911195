import React, { Fragment, useState, useEffect } from "react";
// Component
// import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Button } from "react-bulma-components";
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom";
// import Slider from "react-slick"
import { ReactComponent as ButtonRightArrowIcon } from "./images/ic_left_arrow_white.svg";
import { ReactComponent as RightArrowIcon } from "./images/ic_left_arrow_blue.svg";
import { ReactComponent as LeftArrowIcon } from "./images/ic_left_arrow_blue.svg";
import ShoppingCartItemImage from "./images/item.png";
import "./ShoppingCart.scss";
import Cookies from "universal-cookie";
import MyGlobleSetting from "../../../MyGlobleSetting";
const cookies = new Cookies();

function ShoppingCart() {
  const [changeData, setChangeData] = useState(false);
  function removeCart(id) {
    const getcook = cookies.get("products");
    if (getcook) {
      var obj1 = JSON.parse(JSON.stringify(getcook));
      var newArr = [];
      var id1 = 0;
      for (var i = 0; i < obj1.length; i++) {
        if (obj1[i]["id"] != id) {
          newArr[id1] = obj1[i];
          id1++;
        }
      }

      cookies.set("products", JSON.stringify(newArr), { path: "/" });
      document.getElementsByClassName("add-to-card-number")[0].innerText =
        newArr.length;
      //window.location.reload();
    }
    setChangeData((prevState) => !prevState);
  }

  function updateCart(id) {
    const getcook = cookies.get("products");
    if (getcook) {
      var obj1 = JSON.parse(JSON.stringify(getcook));

      var newArr = [];
      for (var i = 0; i < obj1.length; i++) {
        if (obj1[i]["id"] == id) {
          obj1[i]["qty"] =
            document.getElementsByName("qty[]")[i > 2 ? 3 - i : i].value;
          newArr[i] = obj1[i];
        } else {
          newArr[i] = obj1[i];
        }
      }
      cookies.set("products", JSON.stringify(newArr), { path: "/" });
    }
    console.log("updateCart");
    setChangeData((prevState) => !prevState);
    // window.location.reload(false);
  }

  const response = cookies.get("products");
  console.log("response", response);
  const [data, setData] = useState([]),
    [products, setProduct] = useState([]),
    [page, setPage] = useState(0),
    [sum, setSum] = useState(0),
    maxPage = Math.ceil(data.length / 3),
    onNextPage = () => setPage((page + 1) % maxPage),
    onPrevPage = () => setPage((page - 1) % maxPage);
  useEffect(() => {
    async function fetchData() {
      // const response = cookies.get('products')
      const fetchedData = await response;
      setData(fetchedData);
      setSum(
        fetchedData.reduce(function (prev, current) {
          return prev + +(current.price * current.qty);
        }, 0)
      );
    }
    function getData(pageNumber) {
      // console.log(pageNumber + "getData");
      fetch(MyGlobleSetting.url + "products/7?page=" + pageNumber)
        .then((response) => response.json())
        .then((json) => setProduct(json.data));
    }
    if (response) {
      fetchData();
      getData();
    }
  }, [changeData]);
  let ids = data.map((x) => x.id);

  useEffect(() => {
    console.log(changeData);
  }, [changeData]);
  return (
    <Fragment>
      <div className=" py-100px">
        <div style={{ background: '#1682C5', borderRadius: '20px', width: 'fit-content', padding: '24px', maxWidth: '1344px', margin: '0 auto 32px' }}>
          <h2 style={{ color: '#fff', fontSize: '20px' }}>Welcome to our new store. If you have any issues please contact us at anita@poweroften.ca</h2>
        </div>
        <div className="container">
          <div className="">
            <span className="is-size-8">Store > Cart</span>
            <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">
              Shopping Cart
            </h3>
          </div>

          <div className="">
            <div className="columns is-variable is-8-tablet">
              <div className="column is-three-fifths">
                <div className="field form-group mb-30px">
                  {/* <div className="control checkbox-control">
                                        <input type="checkbox"  name="checkAll" id="all-items"/>
                                        <label className="checkbox-label"  for="all-items">Select/Deselect all items</label>
                                    </div> */}
                </div>

                <div className="shopping-cart-list border-t border-dark mb-60px">
                  {data.slice(page * 3, 3 * (page + 1)).map((content, key) => (
                    <div className="shopping-cart-item border-b border-dark">
                      <div className="is-flex is-align-items-center">
                        <div className="field form-group">
                          <div className="control checkbox-control"></div>
                        </div>
                      </div>
                      <div className="">
                        <img
                          src={MyGlobleSetting.productimgurl + content.image}
                          alt=""
                          className="shopping-cart-item-image"
                        />
                      </div>
                      <div className="is-flex is-align-items-flex-start is-justify-content-center is-flex-direction-column">
                        <h6 className="has-text-weight-bold">{content.name}</h6>
                        <span className="has-text-primary">
                          ${content.price}
                        </span>

                        <input
                          className="form-control"
                          name="qty[]"
                          defaultValue={content.qty}
                          style={{ borderRadius: 5, width: '100%', height: '100%', border: '1px #2a2a2a solid', padding: 8 }}>
                          {/* <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option> */}
                        </input>
                        <div style={{ "margin-top": "20px" }}>
                          <div
                            style={{ width: "600px", display: "table-cell" }}
                            onClick={() => updateCart(content.id)}>
                            <button className="button is-primary button">
                              UPDATE
                            </button>
                          </div>
                          <div style={{ display: "table-cell" }}>
                            <button
                              onClick={() => removeCart(content.id)}
                              className="button is-danger button">
                              REMOVE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="is-flex is-align-items-center is-justify-content-space-between">
                  <Link to="/store" className="has-text-primary is-uppercase">
                    Back to Store
                  </Link>
                  {data.length > 3 ? (
                    <div className="">
                      <ul className="shopping-cart-pagination">
                        <li>
                          <Link onClick={onPrevPage} disabled={!page}>
                            <span className="pagination-icon prev">
                              {" "}
                              <LeftArrowIcon />{" "}
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={onNextPage}
                            disabled={page == Math.ceil(data.length / 3) - 1}>
                            <span className="pagination-icon next">
                              {" "}
                              <RightArrowIcon />{" "}
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="column">
                <div className="shopping-cart-box">
                  <div className="px-30px py-50px">
                    <div className="is-flex is-align-items-center is-justify-content-space-between mb-20px">
                      <h5 className="has-text-weight-bold has-text-primary is-size-5">
                        Subtotal{" "}
                        <span className="has-text-weight-normal has-text-dark">
                          ({data.length} Items)
                        </span>{" "}
                      </h5>
                      <span className="has-text-weight-bold has-text-primary is-size-5">
                        ${sum.toFixed(2)}
                      </span>
                    </div>

                    <div className="mb-20px">
                      <Link to={(data.length == 0) ? "#" : "/checkout"} >
                        <button
                          type="button"
                          className="button is-primary is-fullwidth"
                          disabled={(data.length == 0)}>
                          <span className="mr-20px"> Proceed to Checkout</span>
                          <ButtonRightArrowIcon />
                        </button>
                      </Link>
                    </div>

                    {/*<div className="">
                                            <div className="field default-form-group">
                                                <div className="control">
                                                    <input className="input" type="text" placeholder="PROMO CODE" />
                                                </div>
                                            </div>
                                        </div>*/}
                  </div>
                  <div className="p-30px has-background-light">
                    <h5 className="has-text-weight-bold has-text-primary is-size-5 mb-15px">
                      Complete Your Set
                    </h5>
                    <div className="shopping-cart-list complete-set-list">
                      {products
                        .filter((x) => !ids.includes(x.id))
                        .map((product) => (
                          <div className="shopping-cart-item">
                            <div className="is-flex is-align-items-center">
                              <div className="field form-group"></div>
                            </div>
                            <div className="">
                              <img
                                src={
                                  MyGlobleSetting.productimgurl +
                                  product.feature_image
                                }
                                alt=""
                                className="shopping-cart-item-image"
                              />
                            </div>
                            <div className="is-flex is-align-items-flex-start is-justify-content-center is-flex-direction-column">
                              <h6 className="has-text-weight-bold">
                                {product.title}
                              </h6>
                              <span className="has-text-primary">
                                $ {product.current_price}
                              </span>
                              <div>
                                <button
                                  onClick={() => {
                                    const getcook = cookies.get("products");
                                    if (getcook) {
                                      cookies.set(
                                        "products",
                                        [
                                          ...getcook,
                                          {
                                            id: product.id,
                                            name: product.title,
                                            price: product.current_price,
                                            image: product.feature_image,
                                            qty: 1,
                                          },
                                        ],
                                        { path: "/" }
                                      );
                                    } else {
                                      cookies.set(
                                        "products",
                                        [
                                          {
                                            id: product.id,
                                            name: product.title,
                                            price: product.current_price,
                                            image: product.feature_image,
                                            qty: 1,
                                          },
                                        ],
                                        { path: "/" }
                                      );
                                    }
                                    window.location.reload();
                                  }}
                                  className="button is-primary-red button-with-right-arrow is-size-3 radius-10px">
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ShoppingCart;
