import React, { Component, Fragment } from 'react'
import '../../App.scss';
import Layout from '../layout/layout';
import Hero from './hero/hero';
import Teach from './teach/teach';
import OurPhilosophy from './our-philosophy/OurPhilosophy';
import Author from './author/Author';
import Power from './power/Power';
import { Helmet } from "react-helmet";

class About extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>About Us and Our Philosophy | Power of Ten</title>
          <meta name="description" content="Power of Ten is a visual approach to mathematics instruction and learning, the cards are language neutral and can be utilized in classrooms worldwide. Activities are readily adaptable to non-French or non-English language instruction." />
        </Helmet>
        <Layout>
          <Hero />
          <Teach />
          <OurPhilosophy />
          <Author />
          <Power />
        </Layout>
      </Fragment>
    )
  }
}

export default About