import React, { Component, Fragment } from 'react';
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button';
// import { Link } from "react-router-dom";
import "./ProductReviews.scss";
import StarRate from "./images/stars.png";
import { ReactComponent as RightArrowIcon } from "./images/ic_up_arrow.svg";
import { ReactComponent as LeftArrowIcon } from "./images/ic_up_arrow.svg";
import BgLine from './images/line.png';
import Modal from 'react-bulma-components/lib/components/modal';
import MyGlobleSetting from '../../../../MyGlobleSetting';

import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
// import "yup-phone";

const validationObject = Yup.object().shape({
    name: Yup.string().min(2, 'The title needs to be at least 2 char').required(),
    title: Yup.string().min(2, 'The title needs to be at least 2 char').required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
    rating: Yup.string().required(),
    msg: Yup.string().min(2, 'The name needs to be at least 2 char').required(),
});
const initialValues = {
    name: '',
    title: '',
    email: '',
    phone: '',
    rating: '',
    msg: '',
};


class ProductReviews extends Component {
    getData(pageNumber) {
        let path = window.location.pathname;
        let id = path.split('/');
        fetch(MyGlobleSetting.url + 'product-review/' + id[3] + '?page=' + pageNumber)
            .then(response => response.json())
            .then(json => this.setState({ reviews: json.data, pageData: json }));
    }

    constructor(props) {
        super(props);
        this.state = {
            reviews: [],
            pageData: [],
            pageNumber: 1,
        };
    }

    componentDidMount() {
        this.getData(this.state.pageNumber);
    }
    UNSAFE_componentWillReceiveProps() {
        this.getData(this.state.pageNumber);
    }
    // state = {
    //     reviews: [],
    //     pageData: [],
    //     show: false,
    //     url1: ""
    // }

    handleNext = () => {
        if (this.state.reviews.length > 0) {
            var n = this.state.pageNumber;
            n = n + 1;
            this.setState({ pageNumber: n });
            this.getData(n);
        }
    }
    handleBack = () => {
        if (this.state.pageNumber >= 0) {
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({ pageNumber: n });
            this.getData(n);
        }
    }


    open = () => this.setState({ show: true });
    close = () => this.setState({ show: false });

    render() {

        const { reviews } = this.state;

        return (
            <Fragment>
                <div className="product-reviews-section py-60px">
                    <div className="text-right dash-line">
                        <img src={BgLine} alt="" className="bg-top-line" />
                    </div>

                    <div className="container">
                        <div className="">
                            <h3 className="is-size-24 has-text-weight-bold mt-0 mb-30px">Product Reviews</h3>
                        </div>

                        <div className="mb-50px product-review-card-list">
                            {reviews.map((productReviewItem, i) => (
                                <div className="product-review-card-item" key={productReviewItem.reviewKey + '-' + i}>
                                    <p>{productReviewItem.comment}</p>
                                    <h5 className="mb-5px has-text-weight-bold">{productReviewItem.reviewHeading}</h5>
                                    <div className="is-flex is-align-items-center is-justify-content-space-between">
                                        <span>{productReviewItem.fname} {productReviewItem.lname}</span>
                                        <img src={StarRate} alt="" style={{ width: "20px", height: "20px" }} className="" />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="is-flex is-align-items-center is-justify-content-space-between">
                            <div>
                                <Button onClick={this.open} className="button is-primary is-size-10 button-with-right-arrow radius-10px mb-0">Leave Review</Button>
                            </div>
                            <div className="">
                                {(reviews && reviews.length > 0) && <ul className="full-free-collection-pagination">
                                    <li>
                                        <a onClick={this.handleBack}>
                                            <span className="pagination-icon prev">  <LeftArrowIcon />   </span>
                                            <span className="pagination-text">Prev</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={this.handleNext}>
                                            <span className="pagination-icon next">  <RightArrowIcon />   </span>
                                            <span className="pagination-text">Next</span>
                                        </a>
                                    </li>
                                </ul>}
                            </div>
                        </div>

                    </div>

                </div>

                <Modal className="modal" show={this.state.show} onClose={this.close} {...this.props.modal} modal={{ closeOnBlur: true, showClose: false }}>
                    <div class="modal-background"></div>
                    <div class="modal-card modal-card-lg">
                        <section class="modal-card-body p-40px">
                            <Formik initialValues={initialValues} validationSchema={validationObject}
                                onSubmit={(values, { resetForm }) => {
                                    let that = this;

                                    let path = window.location.pathname;
                                    let id = path.split('/');
                                    const obj = {
                                        ...values,
                                        "user_id": 1,
                                        "product_id": id && id.length ? id[3] || 0 : 0,
                                        "review": values.rating,
                                    };
                                    fetch(MyGlobleSetting.url + 'add-review',
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(obj)
                                        })

                                        .then(function (response) {
                                            return response.json();
                                        })
                                        .then(function (data) {
                                            console.log(data);
                                            resetForm({})
                                            that.close();

                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                }}>
                                {({ values, handleChange, handleBlur }) => (
                                    <Form>
                                        <div className="columns">
                                            <div className="column">
                                                <div class="field form-group mb-20px">
                                                    <div class="control">
                                                        <input class="input" type="text" placeholder="NAME" name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                                    </div>
                                                    <ErrorMessage className="has-text-danger" name='name' component='div' />
                                                </div>
                                                <div class="field form-group mb-20px">
                                                    <div class="control">
                                                        <input class="input" type="text" placeholder="TITLE" name='title' value={values.title} onChange={handleChange} onBlur={handleBlur} />
                                                    </div>
                                                    <ErrorMessage className="has-text-danger" name='title' component='div' />
                                                </div>
                                                <div class="field form-group mb-20px">
                                                    <div class="control">
                                                        <input class="input" type="text" placeholder="EMAIL" name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                                    </div>
                                                    <ErrorMessage className="has-text-danger" name='email' component='div' />
                                                </div>
                                                <div class="field form-group mb-20px">
                                                    <div class="control">
                                                        <input class="input" type="text" placeholder="PHONE" name='phone' value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                                                    </div>
                                                    <ErrorMessage className="has-text-danger" name='phone' component='div' />
                                                </div>

                                            </div>
                                            <div className="column">
                                                <div class="field form-group mb-20px">
                                                    <div class="control">
                                                        <div class="select">
                                                            <select name="rating" defaultValue={values.rating} onChange={handleChange} onBlur={handleBlur} >
                                                                <option value='' disabled>Rating</option>
                                                                {[1, 2, 3, 4, 5].map((x, i) => <option key={i} value={x} >{x}</option>)}
                                                            </select>
                                                        </div>
                                                        <ErrorMessage className="has-text-danger" name='rating' component='div' />
                                                    </div>
                                                </div>
                                                <div class="field form-group mb-20px">
                                                    {/* <label class="label">Message</label> */}
                                                    <div class="control">
                                                        <textarea class="textarea" placeholder="Message" name="msg" onChange={handleChange} onBlur={handleBlur}></textarea>
                                                    </div>
                                                    <ErrorMessage className="has-text-danger" name='msg' component='div' />
                                                </div>
                                                <div className="columns">
                                                    <div className="column is-flex is-align-items-center">
                                                        <button class="button is-primary button-with-right-arrow mb-0">Submit</button>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <p className="mb-0 has-text-primary is-size-10 has-text-weight-bold">Thank you! We will be in touch.</p>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </section>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

export default ProductReviews