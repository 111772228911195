import React, {Component, Fragment } from 'react'
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import Slider from "react-slick"
// import SliderBgImage from "./images/game-image.png"
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import "./RelatedGames.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';
class RelatedGames extends Component {
constructor(props) {
       super(props);
       this.state = { products: [] };
     }
     componentDidMount(){
      let path = window.location.pathname
      let id = path.split('/');
      fetch(MyGlobleSetting.url + 'product-related/' + id[3])
      .then(response => response.json())
      .then(json => this.setState({ products: json.data }));

    }
    static defaultProps = {
        modal: {},
    }
    state = {
      products:[]
    }
    render() {
        
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
                },
                {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    arrows: true,
                }
                },
                {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                }
                }
            ]
        };

        const { products } = this.state;

        return (
        
        <Fragment>

            <div className="related-gamessection py-100px">

                <div className="container">

                    <div className="text-center mb-60px">
                        <h1 className="mb-20px is-size-24 has-text-weight-bold has-text-primary">Related Games</h1>
                    </div>
                    
                    <div>

                        <Slider {...settings}>
                            {products.map((product) => (
                                <div className="slider-item-box">
                            
                                <div className="slider-item">
                            
                                <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image"/>
                            
                                <div className="slider-item-caption">
                                    <h5 className="mb-15px"> {product.title}</h5>
                                    <p className="mb-15px">{product.summary}</p>
                                    <Button className="button is-small is-primary is-rounded mb-0">Learn More</Button>
                                    <Link to={`/games/category/${product.id}`} className="slider-link-button">
                                        <span className="slider-link-button-inner-text"> Learn More </span>
                                        <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>
                                    </Link>
                                </div>
                            
                                </div>
                            
                            </div>
                            ))}
                        </Slider>

                    </div>

                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default RelatedGames