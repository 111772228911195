import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import FeaturedArticles from './featured-articles/FeaturedArticles';
import BrowseAll from './browse-all/BrowseAll';

class Blog extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <FeaturedArticles/>
          <BrowseAll/>
        </Layout>
      </Fragment>
    )
  }
}

export default Blog