import React, {Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import "./SubstractionMenu.scss"

class SubstractionMenu extends Component {

    render() {
        
        return (
        
        <Fragment>

            <div className="bg-gray-light py-25px">

                <div className="container">

                    <div className="store-page-category-menu">
                        <ul>
                            <li><Link to="/games">Getting Started</Link></li>
                            <li><Link to="/games/addition">Addition</Link></li>
                            <li><Link to="/games/substraction" className="active">Subtraction</Link></li>
                            <li><Link to="/games/multiplication">Multiplication</Link></li>
                        </ul>
                    </div>
                   
                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default SubstractionMenu