import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import Slider from "react-slick"
import SliderBgImage from "./images/game-image.png"
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import "./RelatedFreebies.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';
class RelatedFreebies extends Component {

    constructor(props) {
        super(props);
        this.state = { products: [], featuredproducts:[]};
      }
      componentDidMount(){
       fetch(MyGlobleSetting.url + 'products/2')
       .then(response => response.json())
       .then(json => this.setState({ products: json.data }));
 
       fetch(MyGlobleSetting.url + 'products/featured')
       .then(response => response.json())
       .then(json => this.setState({ featuredproducts: json.data }));
       
       }
     static defaultProps = {
         modal: {},
     }
     state = {
       products:[],
       featuredproducts:[]
 
     }

    render() {
        const { products, featuredproducts } = this.state;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    arrows: true,
                    dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                    }
                }
            ]
        };

        const { sliderItems } = this.state;

        return (
        
        <Fragment>

            {/*<div className="featured-freebies-section py-100px">

                <div className="container">

                    <div className="text-center mb-60px">
                        <h1 className="mb-20px is-size-24 has-text-weight-bold has-text-primary">Related Freebies</h1>
                    </div>

                    <div>

                    <Slider {...settings}>
                        {
                          products.map((product) => (
                            <div className="slider-item-box" key="{product.id}">
                                <div className="slider-item">
                                <Link to={`/freebies/free-download/${product.id}`} className="">
                                    <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image"/>
                                    <div className="slider-item-caption">
                                        <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                            <p className="is-size-7 mb-15px">{product.description}</p>
                                        <Button className="button is-small is-primary is-size-3 is-rounded mb-0">Learn More</Button>
                                            <span className="slider-link-button-inner-text"> Learn More </span>
                                            <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>
                                        
                                    </div>
                                    </Link>
                                </div>
                            </div>
                          ))
                        }
                      </Slider>

                    </div>

                </div>

            </div>*/}

        </Fragment>
        
        )

    }
  
}

export default RelatedFreebies