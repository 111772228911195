import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import GettingStartedMenu from '../video-lessons/getting-started-menu/GettingStartedMenu';
import AllVideoLessons from './all-video-lessons/AllVideoLessons';

class VideoLessons extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <GettingStartedMenu/>
          <AllVideoLessons/>
        </Layout>
      </Fragment>
    )
  }
}

export default VideoLessons