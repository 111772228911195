import React, {Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import "./GettingStartedMenu.scss"

class GettingStartedMenu extends Component {

    render() {
        
        return (
        
        <Fragment>

            <div className="bg-gray-light py-25px">

                <div className="container">

                    <div className="lessons-page-category-menu">
                        <ul>
                            <li><Link to="/lessons">ALL</Link></li>
                            <li><Link to="/lessons/video-lessons">Videos of Games and Lessons</Link></li>
                            {/* <li><Link to="/lessons/smart-board-lessons" className="active">SMART BOARD LESSONS</Link></li> */}
                        </ul>
                    </div>
                   
                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default GettingStartedMenu