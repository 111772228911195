import React, { Component } from "react";
import "./header.scss";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import { ReactComponent as AddToCardIcon } from "./images/cart_icon.svg";
import Cookies from "universal-cookie";
const cookies = new Cookies();
class Header extends Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      isScroll: "",
      isOpen: false,
      webUrl: "",
      cartproducts: 0,
      // websiteUrl: ''
    };
  }

  UNSAFE_componentWillReceiveProps() {
    const getcook = cookies.get("products");
    var d = 0;
    if (getcook) {
      d = JSON.parse(JSON.stringify(getcook));
    }
    this.setState({
      websiteUrl: window.location.href,
      cartproducts: d.length,
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const getcook = cookies.get("products");
    var d = 0;
    if (getcook) {
      d = JSON.parse(JSON.stringify(getcook));
    }
    this.setState({
      websiteUrl: window.location.href,
      cartproducts: d.length,
    });
    console.log("SIZE:" + d.length);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll);
    const getcook = cookies.get("products");
    var d = 0;
    if (getcook) {
      d = JSON.parse(JSON.stringify(getcook));
    }
    this.setState({
      cartproducts: d.length,
    });
    console.log("SIZE44:" + d.length);
  }

  handleScroll(event) {
    const top =
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop;

    if (top > 20) {
      this.setState({
        isScroll: "scrolled",
      });
    } else {
      this.setState({
        isScroll: "",
      });
    }
  }

  toogleMenu = () => {
    const getcook = cookies.get("products");
    var d = 0;
    if (getcook) {
      d = JSON.parse(JSON.stringify(getcook));
    }

    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      cartproducts: d.length,
    }));
    console.log("SIZE2:" + d.length);
  };

  onRouteChanged() {
    this.toogleMenu();
  }

  render() {
    let activeNavigation = this.state.isOpen ? "show" : "";
    return (
      <header
        className={`${[this.state.isScroll].join(" ")} ${activeNavigation}`}>
        <div className="header-top">
          <div className="container text-right">
            <span className="ml-10px is-inline-block has-text-weight-semibold has-text-black">
              {" "}
              Phone:{" "}
              <Link to="/" className="has-text-black">
                (343) 549-6284
              </Link>
            </span>
            <span className="ml-15px is-inline-block has-text-weight-semibold has-text-black">
              {" "}
              Email:{" "}
              <a href="mailto:info@poweroften.ca" className="has-text-black">
                info<span style={{ fontWeight: "700" }}>@</span>poweroften.ca
              </a>
            </span>

            <Link to="/cart" className="add-to-card">
              <AddToCardIcon />
              <span className="add-to-card-number">
                {this.state.cartproducts || "0"}
              </span>
            </Link>
          </div>
        </div>

        <div className="header-bottom">
          <div className="container py-10px header-bottom-menu-container">
            <Link to="/" className="header-site-logo">
              <img src={Logo} alt="" className="site-logo" />
            </Link>

            <div className="header-menu-bar">
              <div
                className={`hamburger ${this.state.isOpen ? "is-active" : ""}`}
                role="button"
                onClick={this.toogleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>

              <ul className="header-menu">
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    exact
                    activeClassName="menu-active"
                    to="/">
                    Home
                  </NavLink>
                </li>
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    activeClassName="menu-active"
                    to="/workshops">
                    Workshops
                  </NavLink>
                </li>
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    activeClassName="menu-active"
                    to="/store">
                    Store
                  </NavLink>
                </li>
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    activeClassName="menu-active"
                    to="/lessons">
                    Videos
                  </NavLink>
                </li>
                {/*<li className="header-menu-item"><NavLink  className="header-menu-link" activeClassName="menu-active" to="/games">Games</NavLink ></li>*/}
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    activeClassName="menu-active"
                    to="/freebies">
                    Freebies
                  </NavLink>
                </li>
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    activeClassName="menu-active"
                    to="/newsletters">
                    Newsletters
                  </NavLink>
                </li>
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    activeClassName="menu-active"
                    to="/about">
                    About
                  </NavLink>
                </li>
                <li className="header-menu-item">
                  <NavLink
                    className="header-menu-link"
                    activeClassName="menu-active"
                    to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className=""></div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
