import React, { Component, Fragment } from 'react'

import { Link } from "react-router-dom"
import "./ProductDetails.scss"

import { ReactComponent as BlueArrow } from "./images/blue_arrow.svg"

import { ReactComponent as FacebookIcon } from "./images/facebook_icon.svg"
import MyGlobleSetting from '../../../../MyGlobleSetting';

import { ReactComponent as TwitterIcon } from "./images/twitter_icon.svg"
import { ReactComponent as PinterestIcon } from "./images/pinterest_icon.svg"

import Line from './images/line.png'
import BgGreyShapeImage from "./images/bg-grey-shape-image.png"
import UpArrow from "./images/up-arrow.png";
import { Parallax } from 'react-parallax';
import Cookies from 'universal-cookie';
import { Collapse } from 'react-collapse';
import classNames from "classnames";
import SweetAlert from 'react-bootstrap-sweetalert';

const cookies = new Cookies();

function createMarkup(string) {
  return { __html: string };
}
var getcook = cookies.get('products');
if (getcook) {
  const data = JSON.parse(JSON.stringify(getcook));
  console.log(data);
}

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [], id: '', name: '', image: '', price: '', activeIndex: null, show: false, obj1: [], addedInCart: false, };
    this.toggleClass = this.toggleClass.bind(this);
  }
  componentDidMount() {
    let path = window.location.pathname;
    let id = path.split('/');
    const getcook = cookies.get('products');
    if (getcook) {
      this.setState({
        obj1: JSON.parse(JSON.stringify(getcook))
      })
    }
    fetch(MyGlobleSetting.url + 'product/' + id[3])
      .then(response => response.json())
      .then(json => this.setState({ ...this.state, products: json, id: json[0].id, name: json[0].title, price: json[0].current_price, image: json[0].feature_image }));
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    // var getcook = cookies.get('products');
    // this.state.obj1 = JSON.parse(JSON.stringify(getcook));
    const getcook = cookies.get('products');
    if (getcook) {
      this.setState({
        obj1: JSON.parse(JSON.stringify(getcook))
      })
    }
    let path = window.location.pathname
    let id = path.split('/');
    fetch(MyGlobleSetting.url + 'product/' + id[3])
      .then(response => response.json())
      .then(async (json) => {
        await this.setState({ products: json, id: json[0].id, name: json[0].title, price: json[0].current_price, image: json[0].feature_image,addedInCart: false, });
      });
  }
  isExisted(localValues, localProductCode) {
    for (var i = 0; i < localValues.length; ++i) {
      if (localValues[i].id == localProductCode) {
        return true;
      }
    }
    return false;
  }
  addNewStudent = () => {
    if (cookies.get('products') != null) {
      const getcook = cookies.get('products');
      if (getcook) {
        this.setState({
          obj1: JSON.parse(JSON.stringify(getcook))
        })
        this.setState({
          obj1: JSON.parse(JSON.stringify(getcook))
        })

      }
      // var getcook = cookies.get('products');
      // this.state.obj1 = JSON.parse(JSON.stringify(getcook));

      if (this.isExisted(this.state.obj1, this.state.id)) {
        this.setState({ show: true })
        // this.state.show = true;
      } else {
        this.state.obj1.push({ id: this.state.id, name: this.state.name, price: this.state.price, image: this.state.image, qty: 1 });
        cookies.set('products', JSON.stringify(this.state.obj1), { path: '/' });
      }

    } else {
      cookies.set('products', [{ id: this.state.id, name: this.state.name, price: this.state.price, image: this.state.image, qty: 1 }], { path: '/' });


    }
    const getcook = cookies.get('products');
    if (getcook) {
      debugger;
      cookies.set('cartproducts', getcook.length);
      document.getElementsByClassName('add-to-card-number')[0].innerText = getcook.length;
    }
    this.setState({
      addedInCart: true
    });
    //window.location.reload(false);
  }


  toggleClass(index, e) {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index
    });
  }

  moreLess(index) {
    if (this.state.activeIndex === index) {
      return (
        <span>
          <img src={UpArrow} alt="" />
        </span>
      );
    } else {
      return (
        <span>
          <BlueArrow />
        </span>
      );
    }
  }



  // static defaultProps = {
  //   modal: {},
  // }
  // state = {
  //   products: [], activeIndex: null
  // }
  // let button;
  // if (this.isExisted(this.state.obj1, this.state.id)) {
  //   button = <Link to="/cart" className="button is-primary-red button-with-right-arrow is-size-10 radius-10px">Go To Cart</Link>;
  // } else {
  //   button = <button onClick={() => this.addNewStudent()} className="button is-primary-red button-with-right-arrow is-size-10 radius-10px">Add to Cart</button>;
  // }

  render() {
    const { products } = this.state;
    const { activeIndex, obj1, id, addedInCart } = this.state;


    return (
      Object.keys(products).map((val, k) =>
        <div k={k} key={products[val].id}>
          <Fragment>
            <div className="store-product-details py-100px" key={products[val].id}>

              <div className="container">

                <div className="text-right dash-line">
                  <img src={Line} alt="" className="top-line" />
                </div>

                <div className="">
                  <span className="is-size-8">Store / Power of Ten / {products[val].catname}  / {products[val].title} </span>
                  <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">{products[val].title}</h3>
                </div>

                <div className="columns is-variable is-7">

                  <div className="column">

                    <div className="freebie-card-placeholder">
                      <img src={MyGlobleSetting.productimgurl + products[val].feature_image} alt="" className="" />
                    </div>

                  </div>

                  <div className="column is-half">
                    <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">{products[val].title}</h3>
                    <span className="product-price-tag is-size-18 has-text-primary">
                      ${products[val].current_price}
                    </span>

                    <h5 className="is-size-19 has-text-weight-bold has-text-primary mb-15px">PRODUCT DESCRIPTION</h5>
                    <p className="mb-20px is-size-10 pr-50px" >
                      <div dangerouslySetInnerHTML={createMarkup(products[val].summary)} />
                    </p>
                    <Collapse isOpened={activeIndex === k}>
                      <div className={classNames("alert alert-info msg", {
                        show: activeIndex === k,
                        hide: activeIndex !== k
                      })} >
                        <p className="mb-20px is-size-10 pr-50px" >
                          <div dangerouslySetInnerHTML={createMarkup(products[val].description)} />
                        </p>
                      </div>
                    </Collapse>

                    <div className="mb-20px">
                      <a onClick={this.toggleClass.bind(this, k)} className={`${this.state.active ? 'is-active' : ''} has-text-primary is-size-10 has-text-weight-bold is-flex is-align-items-center read-more-link`}>
                        Read More {this.moreLess(k)}

                      </a>
                      {/*<Link to="/" className="has-text-primary is-size-16 has-text-weight-bold is-flex is-align-items-center read-more-link">
                                            Read More
                                            <BlueArrow/>  
                                          </Link>*/}

                    </div>

                    {(this.isExisted(obj1, id) || addedInCart) ?
                      <Link to="/cart" className="button is-primary-red button-with-right-arrow is-size-10 radius-10px">Go To Cart</Link>
                      :
                      <button onClick={() => this.addNewStudent()} className="button is-primary-red button-with-right-arrow is-size-10 radius-10px">Add to Cart</button>
                    }

                    <div className="my-20px">
                      <Link to="/" className="has-text-primary is-size-10 has-text-weight-bold is-inline-flex is-align-items-center share-link">
                        Share {addedInCart}
                        <FacebookIcon />
                        <TwitterIcon />
                        <PinterestIcon />
                      </Link>
                    </div>

                  </div>

                </div>

              </div>

              <div className="store-product-details-bg-shape-image">
                <Parallax blur={0} bgImage={BgGreyShapeImage} bgImageAlt="Home Hero Background Image" strength={60}></Parallax>
              </div>
              <SweetAlert show={this.state.show} danger title="Alert!" onConfirm={() => { this.setState({ show: false }); }} onEscapeKey={() => this.setState({ show: false })}
                onOutsideClick={() => this.setState({ show: false })}>
                Product already in cart.
              </SweetAlert>
            </div>
          </Fragment>
        </div>
      )
    )
  }
}

export default ProductDetails