import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./CancellationPolicy.scss"
import BgLine from "./images/line.png"

class CancellationPolicy extends Component {
  render() {
    return (
      <Fragment>
          <div className="py-100px">
            <div className="bg-line dash-line">
              <img src={BgLine} alt="" className="" />
            </div>

            <div className="container">
              <div className="">
                  
                  <h5 className="has-text-primary is-size-21 has-text-weight-bold mb-35px">
                      Cancellation Policy
                  </h5>
                  
                  <p className="mb-0 is-size-8">
                    Workshops or attendance at a Power of Ten conference may be cancelled if notice is received at least sixty days in advance.  If cancelled in less than sixty days, expenses that have already been paid by Power of Ten Educational Consulting Ltd. will need to be fully or partially paid.
                  </p>
                  
              </div>

            </div>

          </div>
      </Fragment>
    )
  }
}

export default CancellationPolicy