import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import GettingStartedMenu from './getting-started-menu/GettingStartedMenu';
import GettingStartedGames from './getting-started-games/GettingStartedGames';
import Power from './power/Power';

class GettingStarted extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <GettingStartedMenu/>
          <GettingStartedGames/>
          <Power/>
        </Layout>
      </Fragment>
    )
  }
}

export default GettingStarted