import React, { Component, Fragment } from "react";
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./ContactForm.scss";
import { ReCaptcha } from "react-recaptcha-google";
import MyGlobleSetting from "../../../MyGlobleSetting";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import Swal from "sweetalert2";
// import "yup-phone";

const validationObject = Yup.object().shape({
  name: Yup.string().required(),
  company: Yup.string()
    .min(2, "The name needs to be at least 2 char")
    .required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  find: Yup.string().required(),
  inquiry: Yup.string().required(),
  msg: Yup.string().min(2, "The name needs to be at least 2 char").required(),
  recaptcha: Yup.string().required(),
});
const initialValues = {
  name: "",
  company: "",
  email: "",
  phone: "",
  find: "",
  inquiry: "",
  msg: "",
  recaptcha: "",
};
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.state = {
      findUs: [],
      inquiry: [],
    };
  }
  componentDidMount() {
    let that = this;
    fetch(MyGlobleSetting.url + "find-us", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        that.setState({ findUs: data });
      })
      .catch((e) => {
        console.log(e);
      });

    fetch(MyGlobleSetting.url + "inquiry", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        that.setState({ inquiry: data });
      })
      .catch((e) => {
        console.log(e);
      });
    if (this.captchaDemo) {
      console.log("started, just a second...");
      this.captchaDemo.reset();
    }
  }
  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token");
  }
  render() {
    const { findUs, inquiry } = this.state;
    return (
      <Fragment>
        <div className="contact-form-section py-60px">
          <div className="container">
            <div className="mb-50px">
              <h3 className="is-size-24 has-text-weight-bold is-inline-block mt-0 mb-15px">
                Ready to book your workshop or have a question?{" "}
                <span className="has-text-primary">Inquire below.</span>
              </h3>
              <p className="has-text-weight-bold is-size-10">
                Please fill in the form below to inquire about a workshop,
                resources, to share successes or to ask questions.
              </p>
            </div>

            <div className="contact-form-box">
              <Formik
                initialValues={initialValues}
                validationSchema={validationObject}
                onSubmit={(values, { resetForm }) => {
                  fetch(MyGlobleSetting.url + "conatct", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                  })
                    .then(function (response) {
                      return response.json();
                    })
                    .then(function (data) {
                      console.log(data);
                      resetForm({});
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                  Swal.fire(
                    "Form Submitted!",
                    "We will get back to you as soon as possible.",
                    "success"
                  );
                }}>
                {({ values, handleChange, handleBlur, setFieldValue }) => (
                  <Form>
                    <div className="columns">
                      <div className="column">
                        <div className="field form-group mb-20px">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="name"
                              placeholder="NAME"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <ErrorMessage
                            className="has-text-danger"
                            name="name"
                            component="div"
                          />
                        </div>
                        <div className="field form-group mb-20px">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              placeholder="COMPANY"
                              name="company"
                              value={values.company}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <ErrorMessage
                            className="has-text-danger"
                            name="company"
                            component="div"
                          />
                        </div>
                        <div className="field form-group mb-20px">
                          <div className="control">
                            <input
                              className="input"
                              type="email"
                              placeholder="EMAIL"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <ErrorMessage
                            className="has-text-danger"
                            name="email"
                            component="div"
                          />
                        </div>
                        <div className="field form-group mb-20px">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              placeholder="PHONE"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <ErrorMessage
                            className="has-text-danger"
                            name="phone"
                            component="div"
                          />
                        </div>
                        <div className="field form-group">
                          <div className="control">
                            <div className="select">
                              <select
                                name="find"
                                defaultValue={values.find}
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                <option value="" disabled>
                                  How did you find us?
                                </option>
                                {findUs.map((x, i) => (
                                  <option key={i} value={x.review_from_id}>
                                    {x.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <ErrorMessage
                              className="has-text-danger"
                              name="find"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="field form-group mb-20px">
                          <div className="control">
                            <div className="select">
                              <select
                                name="inquiry"
                                defaultValue={values.inquiry}
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                <option value="" disabled>
                                  Inquiry Details
                                </option>
                                {inquiry.map((x, i) => (
                                  <option key={i} value={x.enquiry_detail_id}>
                                    {x.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <ErrorMessage
                              className="has-text-danger"
                              name="inquiry"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="field form-group mb-20px">
                          {/* <label class="label">Message</label> */}
                          <div className="control">
                            <textarea
                              className="textarea"
                              placeholder="Enter your message here"
                              name="msg"
                              value={values.msg}
                              onChange={handleChange}></textarea>
                          </div>
                          <ErrorMessage
                            className="has-text-danger"
                            name="msg"
                            component="div"
                          />
                        </div>

                        <div className="columns">
                          <div className="column is-flex is-align-items-center">
                            <div>
                              {/* You can replace captchaDemo with any ref word */}
                              <ReCaptcha
                                ref={(el) => {
                                  this.captchaDemo = el;
                                }}
                                size="normal"
                                data-theme="dark"
                                render="explicit"
                                sitekey="6LcPhXwbAAAAANQ08pPwpBvEFE3XHuS7t3Itp5_V"
                                onloadCallback={this.onLoadRecaptcha}
                                verifyCallback={(response) => {
                                  setFieldValue("recaptcha", response);
                                }}
                              />
                              <ErrorMessage
                                className="has-text-danger"
                                name="recaptcha"
                                component="div"
                              />
                              {/* <code>
                                    1. Add <strong>your site key</strong> in the ReCaptcha component. <br/>
                                    2. Check <strong>console</strong> to see the token.
                                    </code> */}
                            </div>
                          </div>

                          <div className="column is-flex is-align-items-center">
                            <button className="button is-primary button-with-right-arrow mb-0">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ContactForm;
