import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import ShopAllKitsList from './shop-all-kits-list/ShopAllKitsList';
// import { Link } from "react-router-dom"

class ShopAllKits extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <ShopAllKitsList/>
        </Layout>
      </Fragment>
    )
  }
}

export default ShopAllKits