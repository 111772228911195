import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import RelatedFreebies from './related-freebies/RelatedFreebies';
import FreebieDetails from './freebie-details/FreebieDetails';

class FreeDownload extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <FreebieDetails/>
          <RelatedFreebies/>
        </Layout>
      </Fragment>
    )
  }
}

export default FreeDownload