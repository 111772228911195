import React, { Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import Slider from "react-slick"
// import SliderImage from "./images/game-image.png"
import MyGlobleSetting from '../../../MyGlobleSetting';
import { ReactComponent as LightbulbIcon } from "./images/ic_lightbulb.svg"
import CardsImage from './images/Power-Of-Ten-Cards.png'
import "./teaching.scss"

class Teaching extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [] };
  }
  componentDidMount() {
    fetch(MyGlobleSetting.url + 'products/5')
      .then(response => response.json())
      .then(json => this.setState({ products: json.data }));

    // fetch(MyGlobleSetting.url + 'products/featured')
    // .then(response => response.json())
    // .then(json => this.setState({ featuredproducts: json.data }));

  }
  static defaultProps = {
    modal: {},
  }
  state = {
    products: []
  }
  render() {
    const { products } = this.state;
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false
          }
        }
      ]
    };

    return (

      <Fragment>

        <div className="teaching-section py-100px">

          <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>

            <div className="text-center mb-60px container-teaching">
              <h1 className="mb-30px is-size-22-mobile is-size-24-tablet has-text-weight-bold has-text-primary teaching-section-heading">Teaching <br /> Tools + Games</h1>
              <p className="mb-30px is-size-10">
                Games are essential to meaningful learning and are highly motivational.<br />
                <br /><span className="has-text-weight-bold"> Games: </span><br />
                &#8226; Integrate three modes of learning: visual, auditory, and kinesthetic<br />
                &#8226; Develop social skills: leadership, cooperation, and respect,<br />
                &#8226; Develop pattern recognition skills, problem solving strategies, and number sense.<br />
                &#8226; Provide paperless repetition and practice, leading to mastery of fact fluency.

              </p>
              <Link to="/store" style={{ backgroundColor: "#ED1C24" }} className="button primary-link-button is-primary is-rounded">Shop Now</Link>
            </div>

            <div className="cards-image-container">
              <img src={CardsImage} alt="" className="owner-large-img cards" style={{ height: '400px', margin: '0 auto', display: 'block' }} />
            </div>

          </div>
        </div>
      </Fragment >
    )

  }

}

export default Teaching