import React, {Component, Fragment} from 'react'
import '../component/page-not-found/PageNotFound.scss';
import Layout from '../layout/layout';
import { Link } from "react-router-dom"
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import Button from 'react-bulma-components/lib/components/button';

class Success extends Component {
  render() {
    return (
      <Fragment>
          <Layout>
            <div className="page-not-found page-top-section hero-section hero-section-half">
                <div className="container">
                  <div className="text-center">
                      <h1 className="hero-heading mb-10px">Payment Success</h1>
                      <Link to="/" className="button radius-10px button-with-right-arrow">
                        Go Back To Home
                      </Link>
                  </div>
                </div>            
            </div>
          </Layout>
      </Fragment>
    )
  }
}

export default Success; 