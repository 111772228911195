import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import AdditionMenu from './addition-menu/AdditionMenu';
import AdditionGames from './addition-games/AdditionGames';
import Power from './power/Power';

class Addition extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <AdditionMenu/>
          <AdditionGames/>
          <Power/>
        </Layout>
      </Fragment>
    )
  }
}

export default Addition