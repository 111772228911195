import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import FeaturedFreebies from './featured-feebies/FeaturedFreebies';
import FullFreeCollection from './full-free-collection/FullFreeCollection';
import GettingStartedMenuFreebies from './getting-started-menu-freebies/GettingStartedMenuFreebies';

class Freebies extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <GettingStartedMenuFreebies />
          {/*<FeaturedFreebies/>*/}
          <FullFreeCollection/>
        </Layout>
      </Fragment>
    )
  }
}

export default Freebies