import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import "./LessionVideoDetails.scss"
import {ReactComponent as FacebookIcon} from "./images/facebook_icon.svg"
import {ReactComponent as InstaIcon} from "./images/insta_icon.svg"
import {ReactComponent as TwitterIcon} from "./images/twitter_icon.svg"
import {ReactComponent as PinterestIcon} from "./images/pinterest_icon.svg"
import {ReactComponent as EmailIcon} from "./images/email_icon.svg"
import Line from './images/line.png'
import MyGlobleSetting from '../../../../MyGlobleSetting'
import ReactPlayer from "react-player"

class LessionVideoDetails extends Component {

  constructor(props) {
    super(props);
    this.state = { products: [], id:'', name:'', image:'', price:'', activeIndex: null };
    this.toggleClass = this.toggleClass.bind(this);
  }
componentDidMount(){
   let path = window.location.pathname
   let id = path.split('/');
   fetch(MyGlobleSetting.url + 'product/' + id[3])
   .then(response => response.json())
   .then(json => this.setState({ products: json, id:json[0].id, name:json[0].title, price:json[0].current_price, image:json[0].feature_image }));
 }
 componentWillReceiveProps(nextProps, nextState) {
  let path = window.location.pathname
  let id = path.split('/');
  fetch(MyGlobleSetting.url + 'product/' + id[3])
  .then(response => response.json())
  .then(json => this.setState({ products: json, id:json[0].id, name:json[0].title, price:json[0].current_price, image:json[0].feature_image }));
}
toggleClass(index, e) {
  this.setState({
    activeIndex: this.state.activeIndex === index ? null : index
  });
}

state = {
  products:[], activeIndex: null
}
  render() {
    const { products } = this.state;
    const { activeIndex } = this.state;
    return (
      Object.keys(products).map((val, k) => 
      <div k={k}>
      <Fragment>
          <div className="lession-video-details-section py-100px">

              <div className="text-right dash-line">
                <img src={Line} alt="" className="top-line" />
              </div>

              <div className="container">
                
                <div className="mb-60px">
                    <span className="is-size-8">Store / Lessons / Smart Board Lessons / {products[val].title}</span>
                    <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">{products[val].title}</h3>
                </div>

                <div className="lession-video-details-box mb-40px">
                    <ReactPlayer url={products[val].video_url} />
                </div>

                <div className="">
                  <Link to="/" className="has-text-primary is-size-6 has-text-weight-bold is-inline-flex is-align-items-center share-link">
                    Share
                    <FacebookIcon/>  
                    <InstaIcon/>  
                    <TwitterIcon/>  
                    <PinterestIcon/>  
                    <EmailIcon/>  
                  </Link>
                </div>

              </div>

          </div>
      </Fragment>
      </div>
     )
     )
   }
 }
export default LessionVideoDetails