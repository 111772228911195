import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import "./teach.scss"
import AnitaPayanImage from './images/anita-largeimage.jpg'
import Line from './images/line.png'

class Teach extends Component {

  render() {
    return (
      <Fragment>
          <div className="teach-courses-section py-100px">

            <div className="container">

              <div className="columns">

                <div className="column is-half pt-30px">
                
                  <h5 className="is-size-15 has-text-weight-bold has-text-primary mb-15px">MEANINGFUL PROBLEM SOLVING</h5>

                  <h3 className="is-size-24 has-text-weight-bold has-text-primary-red mt-0 mb-5">Improving the <br/> way we teach math.</h3>
                 
                  <p className="mb-30px pr-60px is-size-10">
                  Power of Ten Educational Consulting Ltd. is a proudly Canadian company,
                  developed in Victoria, BC. The cards are printed in Camrose, AB. The
                  business is operated out of Ottawa, ON.<br/><br/>
                  Power of Ten offers on-going support in the form of workshops, in-class
                  demonstrations, virtual support sessions, newsletters, as well as phone and
                  email. Support is offered in English and French.<br/><br/>
                  Since Power of Ten is a visual approach to mathematics instruction and
                  learning, the cards are language neutral and can be utilized in classrooms
                  worldwide. Activities are readily adaptable to non-French or non-English
                  language instruction.<br/>
                  </p>

                  <Link to="/workshops" className="button is-primary-red is-size-10 is-size-6-mobile button-with-right-arrow radius-10px">Professional Development For Educators</Link>

                </div>

                <div className="column px-60px text-center">
                  <img src={AnitaPayanImage} alt="" className="video-conference-image owner-large-img" />
                </div>

              </div>

            </div>

            <div className="text-right dash-line" id="philosophy">
              <img src={Line} alt="" className="bottom-line" />
            </div>

          </div>
      </Fragment>
    )
  }
}

export default Teach