import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import "./games.scss"
import BgShapeLeftSide from "./images/hand-image.png"
import { HashLink } from 'react-router-hash-link';

class Games extends Component {

  render() {

    return (
      
      <Fragment>
          
          <div className="games-section">

            <div className="container">

              <div className="columns">

                <div className="column"></div>

                <div className="column px-30px px-0-tablet">
                
                  <h3 className="games-heading is-size-22-mobile is-size-24-tablet has-text-weight-bold mb-30px">Power of Ten Philosophy</h3>
                  
                  <p className="mb-30px pr-60px-tablet is-size-10">
                    Power of Ten is based on five guiding principles:
                    <br/>
                    &#8226; Meaning
                    <br/>
                    &#8226; Choice
                    <br/>
                    &#8226; Trust
                    <br/>
                    &#8226; Diversity
                    <br/>
                    &#8226; Time
                    <br/>
                  </p>

                  
                  <HashLink to="/about#philosophy" className="button is-primary is-size-10 button-with-right-arrow radius-10px">More Info</HashLink>

                </div>

              </div>

            </div>

            <div className="bg-shape-left-side">
              <img src={BgShapeLeftSide} alt="" className="" />
            </div>

          </div>

      </Fragment>

    )

  }

}

export default Games