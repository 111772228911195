class MyGlobleSetting {
  constructor() {
    //localhost
    // this.productimgurl = 'http://localhost/pot/assets/front/img/product/featured/';
    //  this.url1 = 'http://localhost/pot/';
    //  this.url = 'http://localhost/pot/api/v1/';
    // this.gameimgurl = 'http://localhost/pot/assets/front/img/product/sliders/';
    //live
    this.productimgurl =
      "https://api.poweroften.ca/cp/assets/front/img/product/featured/";
    this.blogimgurl = "https://api.poweroften.ca/cp/assets/front/img/blogs/";
    this.url1 = "https://api.poweroften.ca/cp/admin/";
    this.url = "https://api.poweroften.ca/cp/api/v1/";
    this.gameimgurl =
      "https://api.poweroften.ca/cp/admin/assets/front/img/product/sliders/";
    this.cartproducts = 0;
  }
}
export default new MyGlobleSetting();
