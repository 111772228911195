import React, {Component, Fragment} from 'react'
import './PageNotFound.scss';
import Layout from '../../layout/layout';
import { Link } from "react-router-dom"
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import Button from 'react-bulma-components/lib/components/button';

class PageNotFound extends Component {
  render() {
    return (
      <Fragment>
          <Layout>
            <div className="page-not-found page-top-section hero-section hero-section-half">
                <div className="container">
                  <div className="text-center">
                      <h1 className="hero-heading mb-10px">404</h1>
                      <h3 className="hero-heading is-size-2 mt-0 mb-30px">Page Not Found</h3>
                      <Link to="/" className="button is-primary-red radius-10px button-with-right-arrow">
                        Go Back To Home
                      </Link>
                  </div>
                </div>            
            </div>
          </Layout>
      </Fragment>
    )
  }
}

export default PageNotFound