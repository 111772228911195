import React, { Component, Fragment } from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import GettingStartedMenu from './getting-started-menu/GettingStartedMenu';
import VideoLessons from './video-lessons/VideoLessons';
import SmartBoardLessons from './smart-board-lessons/SmartBoardLessons';
import { Helmet } from "react-helmet";

class All extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Explore Lessons | Power of Ten</title>
          <meta name="description" content="Explore the lessons page where you can find the collection of games and lessons to enrich fact fluency, mastery of math concepts, problem solving, and more." />
        </Helmet>
        <Layout>
          <Hero />
          <GettingStartedMenu />
          <VideoLessons />
          <SmartBoardLessons />
        </Layout>
      </Fragment>
    )
  }
}

export default All