import React, {Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import MyGlobleSetting from '../../../../MyGlobleSetting';
import "./GettingStartedMenu.scss"

class GettingStartedMenuFreebies extends Component {
    constructor(props) {
       super(props);
       this.state = { categories: []};
       this.myDivToFocus = React.createRef()
     }
     componentDidMount(){
      fetch(MyGlobleSetting.url + 'categories')
      .then(response => response.json())
      .then(json => this.setState({ categories: json }));
      
      }
    static defaultProps = {
        modal: {},
    }
    state = {
      categories:[]

    }

    

    render() {
        const { categories } = this.state;
        return (
        
        <Fragment>

            <div className="bg-gray-light py-25px">

                <div className="container">

                    <div className="store-page-category-menu">
                        <ul>
                        
                            <li><a onClick={()=>{document.getElementById('factFluency').scrollIntoView()}}>Fact Fluency</a></li>
                            <li><a onClick={()=>{document.getElementById('games').scrollIntoView()}} >Games</a></li>
                            <li><a onClick={()=>{document.getElementById('grades').scrollIntoView()}} >Grades 4-8</a></li>
                            <li><a onClick={()=>{document.getElementById('posters').scrollIntoView()}} >Posters</a></li>
                            <li><a onClick={()=>{document.getElementById('problemSolving').scrollIntoView()}} >Problem Solving and Problem Posing</a></li>
                            <li><a onClick={()=>{document.getElementById('quickGuides').scrollIntoView()}} >Quick Guides</a></li>
                            <li><a onClick={()=>{document.getElementById('supplemental').scrollIntoView()}} >Supplemental</a></li>
                        
                        {/* {
                          categories.map((category) => (
                            <li key={category.id}><Link to="/" className="">{category.name}</Link></li>
                            ))
                        } */}
                        </ul>
                    </div>
                   
                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default GettingStartedMenuFreebies