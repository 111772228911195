import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./DownloadPackage.scss"
import {ReactComponent as IconRightGreyArrow} from './images/ic_right_grey_arrow.svg'
import BgTop from "./images/bg-top.png"
import BgBottom from "./images/bg-bottom.png"
import { Parallax } from 'react-parallax';

class DownloadPackage extends Component {
  render() {
    return (
      <Fragment>
          <div className="py-100px download-section">

            <div className="container">

              <div className="columns is-4">

                <div className="column is-8">
                  <div className="">
                      
                      <h5 className="has-text-primary is-size-18 has-text-weight-bold mb-20px">
                        Download a Free Pre-Workshop/Conference Package
                      </h5>
                      
                      <h3 className="is-size-24 has-text-weight-bold mt-0 mb-40px">
                      Pre-Conference Preparation
                      </h3>
                      
                      <p className="mb-40px is-size-5">
                        Before attending a Power of Ten conference, download the package below and work through it with your class. This will give you a good baseline reading which will be helpful when you attend the workshop.
                      </p>

                      <div>
                        <a href='http://poweroften.ca/wp-content/uploads/2016/10/K-3-Workshop-and-Conference-Handout-Package-2016-2017.pdf' rel="noreferrer" className="button download-link mb-10px" target="_blank" >K-3 Workshop and Conference Handout Package 2016-2017 <IconRightGreyArrow className="ml-15px"/> </a>
                        <a href='http://poweroften.ca/wp-content/uploads/2016/10/4-8-Workshop-and-Conference-Handout-Package-2016-2017.pdf' rel="noreferrer" className="button download-link" target="_blank" >4-8 Workshop and Conference Handout Package 2016-2017 <IconRightGreyArrow className="ml-15px"/></a>
                      </div> 
                     
                  </div>
                </div>

                <div className="column is-4">


                </div>

              </div>

            </div>

              <div className="download-background-right">
                <Parallax blur={0} bgImage={BgTop} className="download-background-top" bgImageAlt="Home Hero Background Image" strength={60}></Parallax>
                <Parallax blur={0} bgImage={BgBottom} className="download-background-bottom" bgImageAlt="Home Hero Background Image" strength={60}></Parallax>
              </div>
          </div>
      </Fragment>
    )
  }
}

export default DownloadPackage