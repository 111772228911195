import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import "./Power.scss"
import HandImage from "./images/hand-card.png"
import RedGridCardImage from "./images/red-grid-card.png"
import RedGridCardRightTopImage from "./images/red-grid-card-right-top.png"
import { Parallax } from 'react-parallax';

class Power extends Component {
  render() {
    return (
        <Fragment>
            <div className="py-130px z-index-0 is-relative bg-gray-light">

                <div className="container is-relative z-index-2">
                
                    <div className="px-20px text-center">

                        <h3 className="is-size-22 has-text-primary has-text-weight-bold mr-30px is-inline-block mb-30px">
                            The Power of Ten Philosophy
                        </h3>
                        
                        <p className="is-size-10 mb-30px">
                            The Power of Ten System is a set of visual tools (ten-frame cards, place value cards and <br/> 
                            ten-frame egg cartons) designed to help students develop the fundamental underpinnings of <br/>
                            number sense and is based on the premise that over eighty percent of learning is visual <br/>
                            (unless the student is severely visually disabled).
                        </p>

                        <Link to="" className="button primary-link-button is-size-10 is-primary-red is-rounded">Learn More</Link>
                        
                    </div>

                </div>

                <div className="hand-red-grid-card-shape">
                    
                    <div className="hand-shape-image">
                        <Parallax blur={0} bgImage={HandImage} bgImageAlt="Home Hero Background Image" strength={30}></Parallax>
                    </div>
                    
                    <div className="red-grid-card-image">
                        <Parallax blur={0} bgImage={RedGridCardImage} bgImageAlt="Home Hero Background Image" strength={30}></Parallax>
                    </div>
                    
                    <div className="red-grid-card-right-top-image">
                        <Parallax blur={0} bgImage={RedGridCardRightTopImage} bgImageAlt="Home Hero Background Image" strength={30}></Parallax>
                    </div>

                </div>

            </div>
        </Fragment>
    )
  }
}

export default Power