import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./hero.scss"
import FreebiesHeroImage from "./images/freebies-hero-image.png"
import { Parallax } from 'react-parallax';

class Hero extends Component {
  render() {
    return (
      <Fragment>
          <div className="freebies-hero-section hero-section hero-section-full">

            <div className="container">

              <div className="columns">

                <div className="column is-four-fifths">
                  <h5 className="has-text-white is-size-26">Explore</h5>
                  <h1 className="hero-heading is-size-32 mt-0 mb-30px">Freebies</h1>
                </div>

              </div>

            </div>

            <div className="freebies-hero-bg-image">
              <Parallax blur={0} bgImage={FreebiesHeroImage} bgImageAlt="Freebies Hero Background Image" strength={160}></Parallax>
            </div>

          </div>
      </Fragment>
    )
  }
}

export default Hero