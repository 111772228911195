import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import Slider from "react-slick"
// import SliderBgImage from "./images/game-image.png"
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import "./RelatedProducts.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';
class RelatedProducts extends Component {
 
    constructor(props) {
        super(props);
        this.state = { products: [] };
      }
      substr1(str){
        return str.substr(1, 80)+"...";
      }
      componentDidMount(){
      let path = window.location.pathname
      let id = path.split('/');
      fetch(MyGlobleSetting.url + 'related-product/' + id[3])
      .then(response => response.json())
      .then(json => this.setState({ products: json.data }));
  
      
      }
    static defaultProps = {
        modal: {},
    }
    state = {
      products:[],
  
    }
    render() {
        const { products } = this.state;
        var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                arrows: true,
                dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false
              }
            }
        ]
        };


        return (
        
        <Fragment>

            <div className="related-products-section py-100px">

                <div className="container">

                    <div className="text-center mb-60px">
                        <h1 className="mb-20px is-size-22-mobile is-size-24-tablet has-text-weight-bold has-text-primary">Related Products</h1>
                    </div>

                    <div>

                    <Slider {...settings}>
                        {
                          products.map((product) => (
                            <div className="slider-item-box" key="{product.id}">
                                <div className="slider-item">
                                <Link to={`/store/product-details/${product.id}`} className="">
                                    <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image"/>
                                    <div className="slider-item-caption">
                                        <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                         <p className="is-size-7 mb-15px">{this.substr1(product.summary)}</p>
                                        <Button className="button is-small is-primary is-size-3 is-rounded mb-0">Learn More</Button>
                                            {/*<span className="slider-link-button-inner-text"> Learn More </span>
                                            <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>*/}
                                        
                                    </div>
                                    </Link>
                                </div>
                            </div>
                          ))
                        }
                      </Slider>

                    </div>

                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default RelatedProducts