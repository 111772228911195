import React, { Component, Fragment } from "react";
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./AddressCard.scss";
import Line from './images/line.png'
import { ReactComponent as ChatIcon} from './images/chat_icon.svg'
import { ReactComponent as MailIcon} from './images/envelope_icon.svg'

class AddressCard extends Component {
    render() {
        return (
            <Fragment>
                <div className="pb-60px pt-20px">
                    <div className="text-right dash-line">
                        <img src={Line} alt="" className="" />
                    </div>

                    <div className="container my-30px">
                        <div className="columns px-50px is-variable is-6">
                            <div className="column">
                                <div className="address-card">
                                    <ChatIcon/>
                                    <h6>343-549-6284</h6>
                                </div>
                            </div>
                            {/* <div className="column">
                                <div className="address-card">
                                    <MailIcon/>
                                    <h6>
                                        28 Amethyst Crescent
                                        Stittsville, ON K2S 1Z1 <br/>
                                        <small> (Mail Only)</small>
                                    </h6>
                                </div>
                            </div> */}
                            <div className="column">
                                <div className="address-card">
                                    <ChatIcon/>
                                    <h6>info<span className="at">@</span>poweroften.ca</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}

export default AddressCard;
