import React, { Component, Fragment } from "react";
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import "./hero.scss";

class Hero extends Component {
  render() {
    return (
      <Fragment>
        <div className="hero-section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h1 className="hero-heading">Newsletters</h1>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Hero;
