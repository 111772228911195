import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
// import { Link } from "react-router-dom"
import OwnerThumb from './images/anita-smallimage.png'
import "./BlogContent.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';
function createMarkup(string) {
  return {__html: string};
}
class BlogContent extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [], featuredproducts:[]};
  }
  componentDidMount(){
    fetch(MyGlobleSetting.url + 'blog/60')
    .then(response => response.json())
    .then(json => this.setState({ products: json }));
}
static defaultProps = {
    modal: {},
}
state = {
  products:[],
  featuredproducts:[]

}
  render() {
    const { products } = this.state;
    return (
      Object.keys(products).map((val, k) => 
      <Fragment>
          <div className="privacy-policy-form-section py-60px">

            <div className="container">

                <div className="privacy-policy-form-box mb-60px">

                    <h3 className="is-size-20-mobile is-size-24-tablet has-text-weight-bold is-inline-block mt-0 mb-30px">“{products[val].title} ”</h3>

                    <p className="is-size-10 mb-20px">
                    <div dangerouslySetInnerHTML={createMarkup(products[val].content)} />
                   
                    </p>

                   

                    <div className="is-flex is-align-items-center"> 

                      <div className="mr-25px">
                        <img src={OwnerThumb} alt="" className="" width="92" />
                      </div>

                      <div>
                        <h4 className="has-text-weight-bold is-size-10">Anita Payan</h4>
                        <span className="is-size-10">Owner of Power of Ten Learning</span>
                      </div>

                    </div>

                </div>

            </div>

          </div>
      </Fragment>
      )
    )
  }
}

export default BlogContent