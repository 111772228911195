import React, { Component } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import { ReactComponent as IcFacebook } from "./images/facebook.svg";
import { ReactComponent as IcInsta } from "./images/insta.svg";
import { ReactComponent as IcLinkedin } from "./images/linkedin.svg";
import { HashLink } from 'react-router-hash-link';

class Footer extends Component {
  render() {
    return (
      <div className="footer-section">
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-K27882H"
            height="0"
            width="0"
            style="display:none;visibility:hidden"></iframe>
        </noscript>

        <div className="container">
          <div className="">
            {/* <h4 className="footer-heading">Start Teaching Smart. Give Your Students The Best.</h4> */}
            <h4 className="footer-heading">
              Mastering the basic facts visually develops a natural tendency to
              problem-solve.
            </h4>
          </div>

          <div className="p-15px">
            <div className="columns">
              <div className="column is-half">
                <Link to="/">
                  <img src={Logo} alt="" className="" width="125" />
                </Link>

                <p className="my-30px has-text-white pr-60px">
                  The Power of Ten approach uses a set of visual tools
                  (ten-frame cards, place value cards and manuals) designed to
                  help students develop number sense, recognize patterns, attain
                  fact fluency, leading to confident, competent problem solvers.
                </p>

                <div className="footer-social-media">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/poweroften.ca">
                    <IcFacebook />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/anita-payan-0b5b2114a">
                    <IcLinkedin />
                  </a>
                  {/*<a target='_blank' href="https://instagram.com/poweroftenmath?igshid=it4k9al58gvy">
                      <IcInsta />
                    </a>*/}
                </div>
              </div>

              <div className="column">
                <h5 className="has-text-white my-30px has-text-weight-semibold">
                  Useful Links
                </h5>
                <div className="columns is-mobile">
                  <ul className="column">
                    <li>
                      <Link to="/workshops" className="has-text-white">
                        Workshops
                      </Link>
                    </li>
                    <li>
                      <Link to="/store" className="has-text-white">
                        Store
                      </Link>
                    </li>
                    <li>
                      <HashLink to="/about#testimonials" className="has-text-white">
                        Testimonials
                      </HashLink>
                    </li>
                    <li>
                      <Link to="/contact" className="has-text-white">
                        Contact
                      </Link>
                    </li>
                    {/* <li><Link to="/freebies/free-download" className="has-text-white">Download</Link></li> */}
                  </ul>
                  <ul className="column">
                    <li>
                      <Link to="/newsletters" className="has-text-white">
                        Newsletters
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" className="has-text-white">
                        Privacy Policy
                      </Link>
                    </li>
                    {/*<li><Link to="/blog" className="has-text-white">Blog</Link></li>*/}
                    <li>
                      <Link to="/cart" className="has-text-white">
                        Cart
                      </Link>
                    </li>
                    <li>
                      <Link to="/checkout" className="has-text-white">
                        Checkout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="column">
                <div className="has-text-white mb-10px">
                  <h5 className="has-text-white my-30px has-text-weight-semibold">
                    Professional Development
                  </h5>
                  <span className="is-size-7">
                    “High quality professional development is characterized by
                    sustained, coherent, collaborative, and job-embedded
                    learning.” E. Sheninger
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-copyright">
            <p className="">
              {" "}
              Power of Ten | Providing resources for the teaching of mathematics
              at the elementary school level.{" "}
            </p>
            <p className="">
              Copyright &copy; 2021 Power of Ten. All Rights Reserved. Website
              Design & Developed by{" "}
              <a
                href="https://www.socialsquared.ca/"
                style={{ color: "#ffc107" }}
                target="_blank">
                SocialSquared Inc
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
