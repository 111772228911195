import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import "./courses.scss"
import ConferenceWorkshopImage from './images/conference-workshop.png'
import VideoConferenceImage from './images/video-conference.png'
import CursesSectionBg from './images/bg-line-shape.png'
import Line from './images/line.png'
import {ReactComponent as IconUpArrow} from './images/up-arrow.svg'
import {ReactComponent as IconGears} from './images/gears_icon.svg'
import {ReactComponent as IconFun} from './images/fun_icon.svg'
import {ReactComponent as IconParents} from './images/parents_icon.svg'
import {ReactComponent as IconTeacher} from './images/teacher_icon.svg'
import { Parallax } from 'react-parallax';
import Slider from "react-slick"

class Courses extends Component {

  render() {

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false
          }
        }
      ]
    };


    return (
      <Fragment>
          <div className="courses-section">

            <div className="container">

              <div className="columns courses-card-section">
                
                
                  <Slider {...settings}>

                    <div className="column mb-20px">
                        <div className="courses-flow-card">
                    
                            <div className="course-icon">
                              <IconGears />
                            </div>

                            <h6 className="course-title has-text-weight-bold my-10px">Why It Works</h6>

                            <p className="course-details mb-10px">
                              Basic math – we use it every day.  How do we learn it, and when?  Studies suggest that subitizing (identifying quantities without counting) is crucial to the development of number sense and basic fact acquisition.  Power of Ten cards teach children ages 3 and up to subitize as they play. 
                            </p>

                            <span className="course-arrow has-background-primary">
                              <IconUpArrow />
                            </span>

                        </div>
                    </div>
                    <div className="column mb-20px">
                        <div className="courses-flow-card">
                        
                            <div className="course-icon">
                              <IconFun />
                            </div>

                            <h6 className="course-title has-text-weight-bold my-10px">Make It Fun</h6>

                            <p className="course-details mb-10px">
                              Children learn most of what they know from parents, siblings, and classmates.  A child models what they think they want to be, so it is important that they experience that math is fun, interesting, and useful.  Power of Ten cards are designed to help us visualize basic facts in a fun, interesting way.
                            </p>

                            <span className="course-arrow has-background-primary-yellow">
                              <IconUpArrow />
                            </span>

                        </div>
                    </div>
                    <div className="column mb-20px">
                      <div className="courses-flow-card">
                          
                          <div className="course-icon">
                            <IconParents />
                          </div>

                          <h6 className="course-title has-text-weight-bold my-10px">For Parents</h6>

                          <p className="course-details mb-10px">
                            We know young children love being read to and that reading with your child is the keystone to literacy.  The same applies to math.  Children develop basic numeracy concepts at an early age.  Playing Power of Ten games with your child builds a strong foundation in math.
                          </p>

                          <span className="course-arrow has-background-primary-red">
                            <IconUpArrow />
                          </span>

                      </div>

                    </div>

                    <div className="column mb-20px">

                      <div className="courses-flow-card">
                          
                          <div className="course-icon">
                            <IconTeacher />
                          </div>

                          <h6 className="course-title has-text-weight-bold my-10px">For Teachers</h6>

                          <p className="course-details mb-10px">
                            Using Power of Ten in your classroom helps your students develop strategies, pattern recognition and a problem-solving mindset, equipping them for more advanced concepts.  The Power of Ten approach teaches math in an integrated way, paralleling the way the brain learns language. By spiraling the math curriculum throughout the year, students deepen their conceptual knowledge leading to confident, competent problem-solvers.
                          </p>

                          <span className="course-arrow has-background-primary">
                            <IconUpArrow />
                          </span>

                      </div>

                    </div>

                  </Slider>



              </div>

              <div className="columns">

                <div className="column mb-50px mb-0-tablet">
                
                  <h5 className="is-size-15 has-text-weight-bold has-text-primary mb-15px">ONLINE &amp; IN-PERSON</h5>

                  <h3 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold has-text-primary-red mt-0 mb-5">Professional Development Workshops</h3>
                 
                  <p className="mb-30px pr-60px-tablet is-size-10">
                    Anita’s workshops show teachers how to easily implement Power of Ten material and its approach in today’s diverse classrooms.  Workshops are presented in English or French, supporting teachers, administrators, and parents in their quest to improve instructional delivery of mathematics.
                  </p>

                  <Link to="/workshops" className="button is-primary-red is-size-10 is-size-6-mobile button-with-right-arrow radius-10px">Professional Development For Educators</Link>

                </div>

                <div className="column">
                  <img src={VideoConferenceImage} alt="" className="video-conference-image" />
                  <img src={ConferenceWorkshopImage} alt="" className="conference-workshop-image" />
                </div>
              
              </div>

            </div>

            <div className="curses-section-bg">
              <Parallax blur={0} bgImage={CursesSectionBg} bgImageAlt="Curses Section Background Shape" strength={200}></Parallax>
            </div>

            <div className="text-right dash-line">
              <img src={Line} alt="" className="bottom-line" />
            </div>

          </div>
      </Fragment>
    )
  }
}

export default Courses