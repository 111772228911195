import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import ShopAllLessonList from './shop-all-lesson-list/ShopAllLessonList';
// import { Link } from "react-router-dom"

class ShopAllLesson extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <ShopAllLessonList/>
        </Layout>
      </Fragment>
    )
  }
}

export default ShopAllLesson