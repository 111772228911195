import React, {Component, Fragment} from 'react'
import './PrivacyPolicy.scss';
import Layout from '../layout/layout';
import Hero from './hero/hero'
import PrivacyPolicyForm from './privacy-policy-form/PrivacyPolicyForm'

class PrivacyPolicy extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <PrivacyPolicyForm/>
        </Layout>
      </Fragment>
    )      
  }
}

export default PrivacyPolicy