import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import ProductDetails from './product-details/ProductDetails';
import ProductReviews from './product-reviews/ProductReviews';
import RelatedProducts from './related-products/RelatedProducts';

class StoreProductDetails extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/> 
          <ProductDetails/>
          <ProductReviews/>
          <RelatedProducts/>
        </Layout>
      </Fragment>
    )
  }
}

export default StoreProductDetails