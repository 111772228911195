import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import "./owner.scss"
import OwnerBoxImage from './images/anita-largeimage.jpg'
import OwnerThumb from './images/anita-smallimage.jpg'
import { HashLink } from 'react-router-hash-link';

class Owner extends Component {
  render() {
    return (
      <Fragment>
          <div className="owner-section">

            <div className="container">

              <div className="columns">

                <div className="column text-center order-1-tablet owner-box-image-desktop">
                  <img src={OwnerBoxImage} alt="" className="owner-large-img" />
                </div>

                <div className="column">
                
                  <h3 className="owner-heading is-size-22-mobile is-size-24-tablet has-text-weight-bold mr-30px is-inline-block">Meet Anita</h3>

                  <HashLink to="/about#philosophy" className="button primary-link-button is-primary is-rounded">Read More</HashLink>
                  
                  <p className="my-30px pr-60px-tablet is-size-10">
                    Since being introduced to Power of Ten by Trevor Calkins in the late 1990s, Anita transformed her approach to teaching math.  She has helped students and parents learn to love math.  She has supported math teachers in BC, AB, ON, NB, NS, FL and NY. She has seen teachers, students and parents conquer their math phobia. 
                  </p>

                  <div className="owner-box-image-mobile">
                    <img src={OwnerBoxImage} alt="" className="" />
                  </div>
                  
                  <div className="is-flex is-align-items-center"> 

                    <div className="mr-25px">
                      <img src={OwnerThumb} alt="" className="owner-small-img" width="92" />
                    </div>

                    <div>
                      <h4 className="has-text-weight-bold is-size-10">Anita Horsman (Payan)</h4>
                      <span className="is-size-5">Owner of Power of Ten Educational Consulting Ltd.</span>
                    </div>

                  </div>

                </div>


                
              </div>

            </div>

          </div>
      </Fragment>
    )
  }
}

export default Owner