import React, { Component, Fragment } from "react";
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from "react-bulma-components/lib/components/button";
import { Link } from "react-router-dom";
// import Slider from "react-slick"
// import SliderBgImage from "./images/game-image.png"
import MyGlobleSetting from "../../../../MyGlobleSetting";
import { ReactComponent as LightbulbIcon } from "./images/ic_lightbulb.svg";
import { ReactComponent as RightArrowIcon } from "./images/ic_up_arrow.svg";
import { ReactComponent as LeftArrowIcon } from "./images/ic_up_arrow.svg";
import "./FullFreeCollection.scss";

class FullFreeCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      pageData: [],
      pageNumber: 1,
      current_page: "",
      next_page: "",
      last_page: "",
      show: false,
      url1: "",
    };
  }

  getData(pageNumber) {
    fetch(MyGlobleSetting.url + "products/2?page=" + pageNumber)
      .then((response) => response.json())
      .then((json) =>
        this.setState({ products: json.data, pageData: json.data })
      );
  }
  async componentDidMount() {
    await this.getData(this.state.pageNumber);
  }
  static defaultProps = {
    modal: {},
  };

  handleNext = () => {
    if (this.state.pageNumber < 6) {
      var n = this.state.pageNumber;
      n = n + 1;
      this.setState({ pageNumber: n });
      this.getData(n);
    }
  };
  handleBack = () => {
    if (this.state.pageNumber >= 1) {
      var n = this.state.pageNumber;
      n = n - 1;
      this.setState({ pageNumber: n });
      this.getData(n);
    }
  };

  render() {
    const { products } = this.state;

    let quickGuides = [];
    let posters = [];
    let factFluency = [];
    let problemSolving = [];
    let games = [];
    let grades48 = [];
    let other = [];

    //Determine category
    //Stock
    //1 = quick guides
    //2 = posters
    //3 = fact fluency
    //4 = problem solving
    //5 = games
    //7 = grades 4-8
    for (var i = 0; i < products.length; i++) {
      if (products[i].stock == 1) {
        quickGuides.push(products[i]);
      } else if (products[i].stock == 2) {
        posters.push(products[i]);
      } else if (products[i].stock == 3) {
        factFluency.push(products[i]);
      } else if (products[i].stock == 4) {
        problemSolving.push(products[i]);
      } else if (products[i].stock == 5) {
        games.push(products[i]);
      } else if (products[i].stock == 7) {
        grades48.push(products[i]);
      } else {
        other.push(products[i]);
      }
    }

    return (
      <Fragment>
        <div className="full-free-collection-section py-100px">
          <div className="container">
            {/*Fact Fluency */}
            <div id="factFluency" className="mb-60px">
              <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                Fact Fluency
              </h2>
            </div>

            <div className="full-free-collection-grid">
              {factFluency.map((product) => (
                <div className="slider-item-box" key={product.id}>
                  <div className="slider-item">
                  <Link
                        to={`/freebies/free-download/${product.id}`}
                        className="">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + product.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />

                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                      {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                      <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                        Learn More
                      </Button>
                       {/*<span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>*/}
                     
                    </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {/*Games */}
            <div id="games" className="mb-60px">
              <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                Games
              </h2>
            </div>
            <div className="full-free-collection-grid">
              {games.map((product) => (
                <div className="slider-item-box" key={product.id}>
                  <div className="slider-item">
                  <Link
                        to={`/freebies/free-download/${product.id}`}
                        className="">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + product.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />

                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                      {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                      <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                        Learn More
                      </Button>
                        {/*<span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>*/}
                      
                    </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {/*Grades 4-8 */}
            <div id="grades" className="mb-60px">
              <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                Grades 4-8
              </h2>
            </div>
            <div className="full-free-collection-grid">
              {grades48.map((product) => (
                <div className="slider-item-box" key={product.id}>
                  <div className="slider-item">
                  <Link
                        to={`/freebies/free-download/${product.id}`}
                        className="">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + product.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />

                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                      {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                      <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                        Learn More
                      </Button>
                        {/*<span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>*/}
                      
                    </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {/*Posters */}
            <div id="posters" className="mb-60px">
              <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                Posters
              </h2>
            </div>

            <div className="full-free-collection-grid">
              {posters.map((product) => (
                <div className="slider-item-box" key={product.id}>
                  <div className="slider-item">
                  <Link
                        to={`/freebies/free-download/${product.id}`}
                        className="">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + product.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />

                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                      {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                      <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                        Learn More
                      </Button>
                        {/*<span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>*/}
                     
                    </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {/*Problem Solving and Problem Posing */}
            <div id="problemSolving" className="mb-60px">
              <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                Problem Solving and Problem Posing
              </h2>
            </div>
            <div className="full-free-collection-grid">
              {problemSolving.map((product) => (
                <div className="slider-item-box" key={product.id}>
                  <div className="slider-item">
                  <Link
                        to={`/freebies/free-download/${product.id}`}
                        className="">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + product.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />

                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                      {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                      <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                        Learn More
                      </Button>
                        {/*<span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>*/}
                      
                    </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {/*Quick Guides */}
            <div id="quickGuides" className="mb-60px">
              <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                Quick Guides
              </h2>
            </div>
            <div className="full-free-collection-grid">
              {quickGuides.map((product) => (
                <div className="slider-item-box" key={product.id}>
                  <div className="slider-item">
                  <Link
                        to={`/freebies/free-download/${product.id}`}
                        className="">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + product.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />
                    
                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                      {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                      <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                        Learn More
                      </Button>
                        {/*<span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>*/}
                    </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {/*Other */}
            <div id="supplemental" className="mb-60px">
              <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                Supplemental
              </h2>
            </div>
            <div className="full-free-collection-grid">
              {other.map((product) => (
                <div className="slider-item-box" key={product.id}>
                  <div className="slider-item">
                  <Link
                        to={`/freebies/free-download/${product.id}`}
                        className="">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + product.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />

                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                      {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                      <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                        Learn More
                      </Button>
                        {/*<span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>*/}
                      
                    </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {/*<div className="p-30px">
                            <ul className="full-free-collection-pagination">
                                <li> 
                                    <a onClick={this.handleBack}> 
                                        <span className="pagination-icon prev">  <LeftArrowIcon/>   </span>
                                        <span className="pagination-text">Prev</span>
                                    </a> 
                                </li>
                                <li> 
                                    <a  onClick={this.handleNext}> 
                                        <span className="pagination-icon next">  <RightArrowIcon/>   </span>
                                        <span className="pagination-text">Next</span>
                                    </a> 
                                </li>
                            </ul>
                        </div>*/}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FullFreeCollection;
