import React, {Component, Fragment} from 'react'
import Layout from '../layout/layout';
import Hero from './hero/hero'
import ShoppingCart from './shopping-cart/ShoppingCart'
// import AddressCard from './address-card/AddressCard'

class Cart extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <ShoppingCart/>
        </Layout>
      </Fragment>
    )      
  }
}

export default Cart