import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import "./BlogDetails.scss"

// import {ReactComponent as BlueArrow} from "./images/blue_arrow.svg"
import {ReactComponent as FacebookIcon} from "./images/facebook_icon.svg"
import {ReactComponent as InstaIcon} from "./images/insta_icon.svg"
import {ReactComponent as TwitterIcon} from "./images/twitter_icon.svg"
import {ReactComponent as PinterestIcon} from "./images/pinterest_icon.svg"
import {ReactComponent as EmailIcon} from "./images/email_icon.svg"
import Line from './images/line.png'
import BgGreyShapeImage from "./images/bg-grey-shape-image.png"
import { Parallax } from 'react-parallax';
import MyGlobleSetting from '../../../../MyGlobleSetting';
function createMarkup(string) {
  return {__html: string};
}

class BlogDetails extends Component {
  constructor(props) {
      super(props);
      this.state = { products: [], featuredproducts:[]};
    }
    componentWillReceiveProps(){
      let path = window.location.pathname;
      let id = path.split('/');
      fetch(MyGlobleSetting.url + 'blog/'+ id[3])
      .then(response => response.json())
      .then(json => this.setState({ products: json }));
    }

  static defaultProps = {
      modal: {},
  }
  state = {
    products:[],
    featuredproducts:[]

  }

  render() {
    const { products } = this.state;
    return (
       Object.keys(products).map((val, k) => 
      <Fragment>
          <div className="blog-details-section py-100px">

            <div className="container">
              
              <div className="text-right dash-line">
                <img src={Line} alt="" className="top-line" />
              </div>
              
              <div className="">
                  <span className="is-size-8">Newsletters / {products[val].catname}  /{products[val].title} </span>
                  <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">{products[val].title} </h3>
              </div>
              
              <div className="columns">
 
                <div className="column pr-60px">

                  <div className="freebie-card-placeholder">
                    <img src={MyGlobleSetting.blogimgurl + products[val].main_image} alt="" className="" />
                  </div>
 
                </div>

                <div className="column is-half">
                
                  <h5 className="is-size-15 has-text-weight-bold has-text-primary mb-15px">ARTICLE OUTLINE</h5>

                  <p className="mb-20px is-size-10 pr-60px">
                  <div dangerouslySetInnerHTML={createMarkup(products[val].content)} />
                  </p>

                  {/* <div className="mb-20px">
                    <Link to="/" className="has-text-primary is-size-6 has-text-weight-bold is-flex is-align-items-center read-more-link">
                      Read More
                      <BlueArrow/>  
                    </Link>
                  </div> */}

                  {/* <Link to="/" className="button is-primary-red button-with-right-arrow radius-10px">Download</Link> */}
                  
                  <div className="my-20px">
                    <Link to="/" className="has-text-primary is-size-10 has-text-weight-bold is-inline-flex is-align-items-center share-link">
                      Share
                      <FacebookIcon/>  
                      <InstaIcon/>  
                      <TwitterIcon/>  
                      <PinterestIcon/>  
                      <EmailIcon/>  
                    </Link>
                  </div>

                  <div className="mt-30px dash-line">
                    <img src={Line} alt="" className="" />
                  </div>


                </div>

              </div>

            </div>

            <div className="blog-details-bg-shape-image">
                <Parallax blur={0} bgImage={BgGreyShapeImage} bgImageAlt="Home Hero Background Image" strength={160}></Parallax>
            </div>

          </div>
      </Fragment>
      )
    )
  }
}

export default BlogDetails