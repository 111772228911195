import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"
import MyGlobleSetting from '../../../../MyGlobleSetting';
import { ReactComponent as LightbulbIcon } from "./images/ic_lightbulb.svg"
import Button from 'react-bulma-components/lib/components/button'
import { ReactComponent as RightArrowIcon } from "./images/ic_up_arrow.svg"
import { ReactComponent as LeftArrowIcon } from "./images/ic_up_arrow.svg"
class ShopAllKitsList extends Component {
    getData(pageNumber) {
        console.log(pageNumber + "getData");
        fetch(MyGlobleSetting.url + 'products/5?page=' + pageNumber)
            .then(response => response.json())
            .then(json => this.setState({ products: json.data }));
    }

    state = {
        products: [],
        pageData: [],
        show: false,
        url1: ""
    }
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            pageData: [],
            pageNumber: 1
        };
    }

    handleNext = () => {
        if (this.state.pageNumber < 2) {
            var n = this.state.pageNumber;
            n = n + 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }
    handleBack = () => {
        if (this.state.pageNumber >= 1) {
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }

    async componentDidMount() {
        await this.getData(this.state.pageNumber);
    }

    static defaultProps = {
        modal: {},
    }

    componentDidUpdate() {
        console.log(this.state.products[0].stock);
    }


    render() {
        const { products } = this.state;

        products.sort(function (a, b) {
            return a.stock - b.stock;
        });

        return (
            <Fragment>

                <div className="full-free-collection-section py-100px">

                    <div className="container">

                        <div className="">
                            <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
                                <ul>
                                    <li><Link to="/store">Online Store</Link></li>
                                    <li class="is-active"><Link to="#" aria-current="page">Power of Ten Kits</Link></li>
                                </ul>
                            </nav>
                            <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">Power of Ten Kits</h3>
                        </div>

                        <div className="full-free-collection-grid">

                            {products.map((product) => (
                                <div className="slider-item-box">

                                    <div className="slider-item">

                                        <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image" />
                                        <span className="slider-item-top-badge">Kit</span>
                                        <div className="slider-item-caption">
                                            <h5 className="mb-15px"> {product.title}</h5>

                                            <Button className="button is-small is-primary is-rounded mb-0">Learn More</Button>
                                            <Link to={`/store/product-details/${product.id}`} className="slider-link-button">
                                                <span className="slider-link-button-inner-text"> Learn More </span>
                                                <span className="slider-link-button-inner-icon"> <LightbulbIcon /> </span>
                                            </Link>
                                        </div>

                                    </div>

                                </div>
                            ))}

                        </div>

                        <div className="p-30px">
                            <ul className="full-free-collection-pagination">
                                <li>
                                    <a onClick={this.handleBack}>
                                        <span className="pagination-icon prev">  <LeftArrowIcon />   </span>
                                        <span className="pagination-text">Prev</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={this.handleNext}>
                                        <span className="pagination-icon next">  <RightArrowIcon />   </span>
                                        <span className="pagination-text">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

            </Fragment>

        )
    }
}

export default ShopAllKitsList