import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
// import Slider from "react-slick"

// import SliderBgImage from "./images/game-image.png"
import MyGlobleSetting from '../../../../MyGlobleSetting';
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import {ReactComponent as RightArrowIcon} from "./images/ic_up_arrow.svg"
import {ReactComponent as LeftArrowIcon} from "./images/ic_up_arrow.svg"
import "./AdditionGames.scss"
import BgGreyShapeImage from "./images/bg-grey-shape-image.png"
import { Parallax } from 'react-parallax';

class AdditionGames extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            products: [],
            pageData:[] ,
            pageNumber:1
        };
    }
     getData(pageNumber){
        fetch(MyGlobleSetting.url + 'product-cat/10/26/'+pageNumber)
        .then(response => response.json())
        .then(json => this.setState({ products: json,pageData:json}));
    }
    async componentDidMount(){
        await this.getData(this.state.pageNumber);
    }
    static defaultProps = {
        modal: {},
    }
    state = {
        products:[],
        pageData : [],
        show: false,
        url1 : ""
    } 

    handleNext = ()=>{
        if(this.state.pageNumber< 2){
        var n = this.state.pageNumber;
        n = n + 1;
        this.setState({pageNumber:n})
        this.getData(n);
         }
     }
     handleBack = ()=>{
        if(this.state.pageNumber >= 1){
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({pageNumber:n})
            this.getData(n);
        }
    }

    render() {
        
        const { products } = this.state;
        return (
        
        <Fragment>

            <div className="full-free-collection-section is-relative z-index-0 py-100px">

                <div className="container">

                    <div className="">
                        <span className="is-size-8">Store / Games / Addition </span>
                        <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">Addition Games</h3>
                    </div>

                    <div className="full-free-collection-grid">

                        {products.map((product) => (
                            <div className="slider-item-box" key="{product.id}">
                        
                                <div className="slider-item">
                            
                                <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image"/>
                            
                                <div className="slider-item-caption">
                                    <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                    {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                                    <Button className="button is-small is-size-10 is-primary is-rounded mb-0">Learn More</Button>
                                    <Link to={`/games/category/${product.id}`} className="slider-link-button">
                                        <span className="slider-link-button-inner-text"> Learn More </span>
                                        <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>
                                    </Link>
                                </div>
                            
                                </div>
                            
                            </div>
                        ))}

                    </div>

                    <div className="p-30px">
                        <ul className="full-free-collection-pagination">
                                <li> 
                                    <a onClick={this.handleBack}> 
                                        <span className="pagination-icon prev">  <LeftArrowIcon/>   </span>
                                        <span className="pagination-text">Prev</span>
                                    </a> 
                                </li>
                                <li> 
                                    <a  onClick={this.handleNext}> 
                                        <span className="pagination-icon next">  <RightArrowIcon/>   </span>
                                        <span className="pagination-text">Next</span>
                                    </a> 
                                </li>
                            </ul>
                    </div>

                </div>
                
                <div className="store-product-details-bg-shape-image">
                    <Parallax blur={0} bgImage={BgGreyShapeImage} bgImageAlt="Home Hero Background Image" strength={160}></Parallax>
                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default AdditionGames