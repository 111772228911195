import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import {ReactComponent as RightArrowIcon} from "./images/ic_up_arrow.svg"
import {ReactComponent as LeftArrowIcon} from "./images/ic_up_arrow.svg"
import "./FeaturedArticles.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';
function createMarkup(string) {
    return {__html: string};
  }
class FeaturedArticles extends Component {
    constructor(props) {
        super(props);
        this.state = { products: [], featuredproducts:[],featuredproducts1:[],pageNumber: 1};
      }
    getData(pageNumber) {
        fetch(MyGlobleSetting.url + 'blog-featured/'+pageNumber)
        .then(response => response.json())
        .then(json => this.setState({ featuredproducts: json }));
    }
    async componentDidMount(){
       fetch(MyGlobleSetting.url + 'blog-categories')
       .then(response => response.json())
       .then(json => this.setState({ products: json.data }));
        
       await this.getData(this.state.pageNumber);
       
       fetch(MyGlobleSetting.url + 'blog-featured1')
       .then(response => response.json())
       .then(json => this.setState({ featuredproducts1: json.data }));
       
       }

       handleNext = () => {
        if (this.state.pageNumber < 2) {
            var n = this.state.pageNumber;
            n = n + 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }
    handleBack = () => {
        if (this.state.pageNumber >= 1) {
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }
     static defaultProps = {
         modal: {},
     }
     state = {
       products:[],
       featuredproducts:[]
 
     }
    render() {
        const { products, featuredproducts,featuredproducts1 } = this.state;
        return (
        
        <Fragment>

            <div className="py-100px">

                <div className="container">

                    <div className="mb-40px">
                        <h1 className="mb-20px is-size-24 has-text-weight-bold">Featured Articles</h1>                        
                    </div>

                    <div className="columns mb-0">
                        <div className="column is-three-quarters pl-0 pt-0 pb-0">
                            {/* <div className="columns is-gapless mb-0">
                                 {
                                   featuredproducts1.map((product) => (  
                                <div className="column is-full">
                                    <div className="slider-item-box">                                        
                                        <div className="slider-item">                                    
                                            <img src={MyGlobleSetting.blogimgurl + product.main_image} alt="" className="slider-item-image"/>                                        
                                            <div className="slider-item-caption">
                                                <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                                <p className="is-size-7 mb-15px"><div dangerouslySetInnerHTML={createMarkup(product.content)} /></p>
                                                <Button className="button is-size-10 is-small is-primary is-rounded mb-0">Learn More</Button>
                                                <Link to={`/blog/article/${product.id}/${product.bcategory_id}`} className="slider-link-button">
                                                    <span className="slider-link-button-inner-text"> Learn More </span>
                                                    <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>
                                                </Link>
                                            </div>                                        
                                        </div>                                
                                    </div>
                                </div>
                                ))}

                            </div> */}

                            <div className="columns is-gapless">
                                {/*
                                 Featured blog 
                                */}
                                    {
                                   featuredproducts.map((product) => ( 
                                        <div className="column">                            
                                            <div className="slider-item-box">
                                                <div className="slider-item">
                                                    <img src={MyGlobleSetting.blogimgurl + product.main_image} alt="" className="slider-item-image"/>
                                                    <div className="slider-item-caption">
                                                        <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                                        <p className="is-size-7 mb-15px"></p>
                                                        <Button className="button is-size-10 is-small is-primary is-rounded mb-0">Learn More</Button>
                                                        <Link to={`/blog/article/${product.id}/${product.bcategory_id}`} className="slider-link-button">
                                                            <span className="slider-link-button-inner-text"> Learn More </span>
                                                            <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {/*
                                 Featured blog end
                                */}
                            </div>
                        </div>
                        <div className="column">
                            <div className="featured-articles-right-panel">
                                <div className="panel-box">
                                    <h5 className="mt-0 mb-20px is-size-4 has-text-primary has-text-weight-bold">Latest Posts</h5>
                                    <ul className="panel-list">
                                    { featuredproducts1.map((product) => (
                                        <li> <Link to={`/blog/article/${product.id}/${product.bcategory_id}`}> {product.title}</Link></li>
                                    ))}
                                    </ul>
                                </div>
                                <div className="panel-box">
                                    <h5 className="mt-0 mb-20px is-size-4 has-text-primary has-text-weight-bold">Categories</h5>
                                    <ul className="panel-list">
                                    { products.map((product) => (
                                        <li> <Link to=""> {product.name}</Link></li>
                                    ))}
                                    </ul>
                                </div>
                                <div className="panel-box">
                                    <h5 className="mt-0 mb-20px is-size-4 has-text-primary has-text-weight-bold">Quick Menu</h5>
                                    <ul className="panel-list">
                                        <li><Link to="">Home</Link></li>
                                        <li><Link to="">Workshops</Link></li>
                                        <li><Link to="">Store</Link></li>
                                        <li><Link to="">Lessons</Link></li>
                                        <li><Link to="">Games</Link></li>
                                        <li><Link to="">Freebies</Link></li>
                                        <li><Link to="">About</Link></li>
                                        <li><Link to="">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div className="py-30px">
                        <ul className="full-free-collection-pagination">
                            <li> 
                                <a onClick={this.handleBack}>
                                    <span className="pagination-icon prev">  <LeftArrowIcon/>   </span>
                                    <span className="pagination-text">Prev</span>
                                </a> 
                            </li>
                            <li> 
                                <a onClick={this.handleNext}>
                                    <span className="pagination-icon next">  <RightArrowIcon/>   </span>
                                    <span className="pagination-text">Next</span>
                                </a> 
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

        </Fragment>
        
        )

    }
  
}

export default FeaturedArticles