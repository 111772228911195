import React, { Component, Fragment } from "react";
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from "react-bulma-components/lib/components/button";
// import axios from 'axios';
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import SliderBgImage from "./images/kits1.png"
// import SliderBgImage1 from "./images/kits2.png"
// import SliderBgImage2 from "./images/kits2.png"
import MyGlobleSetting from "../../../../MyGlobleSetting";
import { ReactComponent as LightbulbIcon } from "./images/ic_lightbulb.svg";
import "./OrderForm.scss";

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [], featuredproducts: [] };
  }
  componentDidMount() {
    fetch(MyGlobleSetting.url + "products/5")
      .then((response) => response.json())
      .then((json) => this.setState({ products: json.data }));

    fetch(MyGlobleSetting.url + "products/featured")
      .then((response) => response.json())
      .then((json) => this.setState({ featuredproducts: json.data }));
  }
  substr1(str) {
    return str.substr(0, 80) + "...";
  }
  static defaultProps = {
    modal: {},
  };
  state = {
    products: [],
    featuredproducts: [],
  };
  render() {
    const { products, featuredproducts } = this.state;
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
      ],
    };

    return (
      <Fragment>
        <div className="full-free-collection-section py-100px">
          <div className="container" id="orderForm">
            <div className="">
              {/* <span className="is-size-8">Store / Featured</span> */}

              {featuredproducts.map((featured) => (
                <div className="slider-item-box" key="{featured.id}">
                  <div className="slider-item">
                    <img
                      src={
                        MyGlobleSetting.productimgurl + featured.feature_image
                      }
                      alt=""
                      className="slider-item-image"
                    />
                    <div className="slider-item-caption">
                      <h5 className="is-size-11 mb-15px"> {featured.title}</h5>
                      <p className="is-size-7 mb-15px">
                        {this.substr1(featured.summary)}
                      </p>
                      <Button className="button is-small is-primary is-size-3 is-rounded mb-0">
                        Learn More
                      </Button>
                      <Link to="/lessons" className="slider-link-button">
                        <span className="slider-link-button-inner-text">
                          {" "}
                          Learn More{" "}
                        </span>
                        <span className="slider-link-button-inner-icon">
                          {" "}
                          <LightbulbIcon />{" "}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className="is-flex-tablet is-align-items-center mt-10px mb-30px"
                ref={this.myDivToFocus}>
                <h3 className="is-size-20-mobile is-size-24-tablet has-text-weight-bold my-0 mb-10px mb-0-tablet mr-30px">
                  Printable Order Form
                </h3>
              </div>
            </div>
            <div>
              <div className="slider-item-box" style={{ width: "50%" }}>
              <Link
                      to={`/freebies/free-download/172`}
                      className="">
                <div className="slider-item">
                  <img src={"image.png"} alt="" className="slider-item-image" />
                  <div className="slider-item-caption">
                    <h5 className="is-size-11 mb-15px">
                      {" "}
                      Printable Order Form{" "}
                    </h5>
                    <Button className="button is-small is-primary is-size-3 is-rounded mb-0">
                      Learn More
                    </Button>
                      {/*<span className="slider-link-button-inner-text">
                        {" "}
                        Learn More{" "}
                      </span>
                      <span className="slider-link-button-inner-icon">
                        {" "}
                        <LightbulbIcon />{" "}
                      </span>*/}
                    
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default OrderForm;
