import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"
import "./hero.scss"
import HomeHeroImage from "./images/hero-hand.png"
import { Parallax } from 'react-parallax';

class Hero extends Component {

  render() {

    return (
      <Fragment>
        <div className="hero-section hero-section-full home-hero-section">

          <div className="container">

            <div className="columns">

              <div className="column">
                <h1 className="hero-heading mb-30px">Setting kids up for<br /> success in math.</h1>
                <Link to="/workshops" style={{ whiteSpace: 'normal' }} className="button is-primary-red radius-10px is-size-10 is-size-6-mobile button-with-right-arrow">
                  Professional Development For Educators
                </Link>
                <Link to="/store" className="button is-primary-blue radius-10px is-size-10 is-size-6-mobile button-with-right-arrow">
                  Resources for Educators and Parents
                </Link>
              </div>
              <div className="column">

              </div>
            </div>

          </div>

          <div className="home-hero-bg-image">
            <Parallax blur={0} bgImage={HomeHeroImage} bgImageAlt="Home Hero Background Image" strength={160}></Parallax>
          </div>

        </div>
      </Fragment>
    )
  }
}

export default Hero