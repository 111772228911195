import React, { Component, Fragment } from 'react'
// import { Link } from "react-router-dom" 
import GroupImage from "./images/group-image.png"
import "./hero.scss"
import WOW from "wow.js";

class Hero extends Component {

  componentDidMount() {
    const wow = new WOW();
    // const wow = WOW();
    wow.init();
  }

  render() {
    return (
      <Fragment>
        <div className="hero-section hero-section-full hero-section-primary">

          <div className="container">

            <div className="columns">

              <div className="column is-flex is-align-items-center">
                <div className="">
                  <h5 className="is-size-23 has-text-weight-regular has-text-white mb-10px">Online &amp; In-Person</h5>

                  <h1 className="hero-heading mb-30px mt-0">Professional <br /> Workshop</h1>
                  <a href="https://calendly.com/poweroftenca" className="button is-primary is-inverted radius-10px is-size-10 button-with-right-arrow blue-icon">
                    Book a Workshop
                  </a>

                </div>
              </div>
              <div className="column text-center px-30px py-15px">
                <img src={GroupImage} alt="" className="wow fadeIn" data-wow-offset="20" data-wow-delay="0.5s" />
              </div>
            </div>

          </div>

        </div>
      </Fragment>
    )
  }
}

export default Hero