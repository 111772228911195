import React, { Component, Fragment, setState } from "react";
import "../../App.scss";
import Layout from "../layout/layout";
import Hero from "./hero/hero";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import DisplayPDF from "./DisplayPDF";
import potnewsletter1 from './potnewsletter1.pdf';
import potnewsletter2 from './potnewsletter2.pdf';
import potnewsletter3 from './potnewsletter3.pdf';
import potnewsletter4 from './potnewsletter4.pdf';
import { Helmet } from "react-helmet";

class Newsletters extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      show: false,
    };
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  submitEmail = () => {
    fetch("https://api.sendgrid.com/v3/marketing/contacts", {
      method: "PUT",
      headers: {
        "Authorization":
          "Bearer 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",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'list_ids': [
          '0e00ba28-0261-4535-8f88-353f437a3c44'
        ],
        'contacts': [
          {
            'email': this.state.email,
            'first_name': this.state.firstName,
            'last_name': this.state.lastName
          }
        ]
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({ 'show': true });
  };

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Newletters | Power of Ten</title>
          <meta name="description" content="Sign up for Power of Ten newsletters to stay in the loop with respect to how you can best be enriching your teaching through cross-curriculum learning." />
        </Helmet>
        <Layout>
          <Hero />
          <div
            style={{ margin: "0 auto", width: "fit-content", padding: "32px" }}>
            <h1 style={{ fontSize: "24px", fontWeight: "700" }}>
              Sign Up for Newsletters
            </h1>
          </div>
          <div style={{ maxWidth: "600px", margin: "0 auto", display: "flex" }}>
            <div className="column">
              <div className="field form-group mb-20px">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="field form-group mb-20px">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field form-group mb-20px">
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div>
                <button
                  className="button is-primary button-with-right-arrow mb-0"
                  style={{ width: "100%", height: "48px" }}
                  onClick={this.submitEmail}
                  disabled={!(this.state.email !== "" && this.state.firstName !== "" && this.state.lastName !== "")}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div
            style={{ margin: "0 auto", width: "fit-content", padding: "32px" }}>
            <h1 style={{ fontSize: "24px", fontWeight: "700" }}>
              Previous Newsletters
            </h1>
          </div>
          <div style={{ paddingBottom: '20px' }} className="newsletters_container">
            <Accordion allowZeroExpanded preExpanded={[1]}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    March 3rd 2021
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <DisplayPDF file={potnewsletter4} />
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    April 4th 2020
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <DisplayPDF file={potnewsletter3} />
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Febuary 8th 2020
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <DisplayPDF file={potnewsletter2} />
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    September 5th 2019
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <DisplayPDF file={potnewsletter1} />
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
          <SweetAlert show={this.state.show} success title="Success!" onConfirm={() => { this.setState({ 'show': false }) }}>
            Your email has been added to the mail list!
          </SweetAlert>
        </Layout>

      </Fragment>
    );
  }
}

export default Newsletters;
