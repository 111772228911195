import React, {Component, Fragment} from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import "./hero.scss"
import AboutHeroImage from "./images/light-bulb.png"
import { Parallax } from 'react-parallax';

class Hero extends Component {
  render() {
    return (
      <Fragment>
          <div className="about-hero-section hero-section hero-section-full">

            <div className="container">

              <div className="columns">

                <div className="column is-four-fifths">
                  <h5 className="has-text-white is-size-24">Bringing math to life.</h5>
                  <h1 className="hero-heading mt-0 mb-30px">Connecting to students <br/> in meaningful ways.</h1>
                  <Link to="/lessons" className="button is-primary is-size-10 mb-0 is-inverted radius-10px button-with-right-arrow blue-icon">
                    Explore Videos
                  </Link>
                </div>
              </div>

            </div>

            <div className="about-hero-bg-image">
              <Parallax blur={0} bgImage={AboutHeroImage} bgImageAlt="About Hero Background Image" strength={160}></Parallax>
            </div>

          </div>
      </Fragment>
    )
  }
}

export default Hero