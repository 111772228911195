import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"
import MyGlobleSetting from '../../../../MyGlobleSetting';
import { ReactComponent as LightbulbIcon } from "./images/ic_lightbulb.svg"
import Button from 'react-bulma-components/lib/components/button'
import { ReactComponent as RightArrowIcon } from "./images/ic_up_arrow.svg"
import { ReactComponent as LeftArrowIcon } from "./images/ic_up_arrow.svg"
class ShopAllLessonList extends Component {
    getData(pageNumber) {
        console.log(pageNumber + "getData");
        fetch(MyGlobleSetting.url + 'products/11')
            .then(response => response.json())
            .then(json => this.setState({ products: json.data }));
    }

    state = {
        products: [],
        pageData: [],
        show: false,
        url1: ""
    }
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            pageData: [],
            pageNumber: 1
        };
    }

    handleNext = () => {
        if (this.state.pageNumber < 2) {
            var n = this.state.pageNumber;
            n = n + 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }
    handleBack = () => {
        if (this.state.pageNumber >= 1) {
            var n = this.state.pageNumber;
            n = n - 1;
            this.setState({ pageNumber: n })
            this.getData(n);
        }
    }

    async componentDidMount() {
        await this.getData(this.state.pageNumber);
    }

    static defaultProps = {
        modal: {},
    }

    render() {
        const { products } = this.state;

        let cfdp = [];
        let mtb = [];
        let tmm = [];
        let other = [];

        //Determine category
        //Stock
        //1 = Connecting Fractions, Decimals and Percentages
        //2 = Mastering the Basics Manual
        //3 = Teachable Moments Manual
        for (var i = 0; i < products.length; i++) {
            if (products[i].stock == 1) {
                cfdp.push(products[i]);
            } else if (products[i].stock == 2) {
                mtb.push(products[i]);
            } else if (products[i].stock == 3) {
                tmm.push(products[i]);
            } else {
                other.push(products[i]);
            }
        }

        return (
            <Fragment>

                <div className="full-free-collection-section py-100px">

                    <div className="container">

                        <div className="">
                            <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
                                <ul>
                                    <li><Link to="/store">Online Store</Link></li>
                                    <li class="is-active"><Link to="#" aria-current="page">Power of Ten Manuals</Link></li>
                                </ul>
                            </nav>
                            <h3 className="is-size-24 has-text-weight-bold mt-10px mb-30px">Power of Ten Manuals</h3>
                        </div>

                        {/*cfdp*/}
                        <div id="cfdp" className="mb-60px" style={{ marginTop: '5%' }}>
                            <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                                Connecting Fractions, Decimals and Percentages
                            </h2>
                        </div>

                        <div className="full-free-collection-grid">
                            {cfdp.map((product) => (
                                <div className="slider-item-box" key={product.id}>
                                    <div className="slider-item">
                                        <Link
                                            to={`/store/product-details/${product.id}`}
                                            className="">
                                            <img
                                                src={
                                                    MyGlobleSetting.productimgurl + product.feature_image
                                                }
                                                alt=""
                                                className="slider-item-image"
                                            />

                                            <div className="slider-item-caption">
                                                <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                                {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                                                <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                                                    Learn More
                                                </Button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/*mtb*/}
                        <div id="mtb" className="mb-60px" style={{ marginTop: '5%' }}>
                            <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                                Mastering the Basics Manual
                            </h2>
                        </div>
                        <div className="full-free-collection-grid">
                            {mtb.map((product) => (
                                <div className="slider-item-box" key={product.id}>
                                    <div className="slider-item">
                                        <Link
                                            to={`/store/product-details/${product.id}`}
                                            className="">
                                            <img
                                                src={
                                                    MyGlobleSetting.productimgurl + product.feature_image
                                                }
                                                alt=""
                                                className="slider-item-image"
                                            />

                                            <div className="slider-item-caption">
                                                <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                                {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                                                <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                                                    Learn More
                                                </Button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/*tmm*/}
                        <div id="tmm" className="mb-60px" style={{ marginTop: '5%' }}>
                            <h2 className="is-size-22-mobile is-size-24-tablet has-text-weight-bold">
                                Teachable Moments Manual
                            </h2>
                        </div>
                        <div className="full-free-collection-grid">
                            {tmm.map((product) => (
                                <div className="slider-item-box" key={product.id}>
                                    <div className="slider-item">
                                        <Link
                                            to={`/store/product-details/${product.id}`}
                                            className="">
                                            <img
                                                src={
                                                    MyGlobleSetting.productimgurl + product.feature_image
                                                }
                                                alt=""
                                                className="slider-item-image"
                                            />

                                            <div className="slider-item-caption">
                                                <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                                {/*<p className="is-size-7 mb-15px">{product.description}</p>*/}
                                                <Button className="button is-size-3 is-small is-primary is-rounded mb-0">
                                                    Learn More
                                                </Button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="p-30px">
                            <ul className="full-free-collection-pagination">
                                <li>
                                    <a onClick={this.handleBack}>
                                        <span className="pagination-icon prev">  <LeftArrowIcon />   </span>
                                        <span className="pagination-text">Prev</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={this.handleNext}>
                                        <span className="pagination-icon next">  <RightArrowIcon />   </span>
                                        <span className="pagination-text">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

            </Fragment>

        )
    }
}

export default ShopAllLessonList