import React, { Component, Fragment } from "react";
import "../../../App.scss";
import Layout from "../../layout/layout";
import Hero from "./hero/hero";
import GettingStartedMenu from "./getting-started-menu/GettingStartedMenu";
import Kits from "./kits/Kits";
import InteractiveLessons from "./interactive-lessons/InteractiveLessons";
import Cards from "./cards/Cards";
import OrderForm from "./order-form/OrderForm";
import { Helmet } from "react-helmet";

class OnlineStore extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Cards, Kits, Manuals, Order Form | Store | Power of Ten</title>
          <meta name="description" content="Shop the store for educational resources for struggling students, enrichment of student curriculum and goals. Find growth over time with on-going regular assessments and meaningful math strategies." />
        </Helmet>
        <Layout>
          <Hero />
          <GettingStartedMenu />
          <Cards />
          <Kits />
          <InteractiveLessons />
          <OrderForm />
        </Layout>
      </Fragment>
    );
  }
}

export default OnlineStore;
