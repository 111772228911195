import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import MultiplicationMenu from './multiplication-menu/MultiplicationMenu';
import MultiplicationGames from './multiplication-games/MultiplicationGames';
import Power from './power/Power';

class Multiplication extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <MultiplicationMenu/>
          <MultiplicationGames/>
          <Power/>
        </Layout>
      </Fragment>
    )
  }
}

export default Multiplication