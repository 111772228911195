import React, {Component, Fragment } from 'react'
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
import Button from 'react-bulma-components/lib/components/button'
import { Link } from "react-router-dom"
import Slider from "react-slick"
// import SliderBgImage1 from "./images/smartboard-image1.png"
// import SliderBgImage2 from "./images/smartboard-image2.png"
// import SliderBgImage3 from "./images/smartboard-image3.png"
import Line from './images/line.png'
import {ReactComponent as LightbulbIcon} from "./images/ic_lightbulb.svg"
import "./SmartBoardLessons.scss"
import MyGlobleSetting from '../../../../MyGlobleSetting';

class SmartBoardLessons extends Component {
        
    constructor(props) {
       super(props);
       this.state = { products: [], pageData:[] , pageNumber:1 };

     }
     substr1(str){
      return str.substr(1, 80)+"...";
    }
     getData(pageNumber){
        fetch(MyGlobleSetting.url + 'product-cat/13/30/'+pageNumber)
        .then(response => response.json())
        .then(json => this.setState({ products: json }));
    }
    async componentDidMount(){ 
        await this.getData(1,this.state.pageNumber);
      }

    static defaultProps = {
        modal: {},
    }
    state = {
        products:[],
        show: false,
    } 
    

    render() {
        
        var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                arrows: true,
                dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false
              }
            }
          ]
        };

        const { products } = this.state;

        return (
        
        <Fragment>
{/* 
            <div className="related-gamessection py-100px">
               
                <div className="text-right mb-30px dash-line">
                    <img src={Line} alt="" className="" />
                </div>

                <div className="container">

                    <div className="mb-40px">
                        <h3 className="is-size-20-mobile is-size-24-tablet has-text-weight-bold mr-60px is-inline-block">Smart Board Lessons</h3>
                        <Link to="/" className="button is-primary is-size-10 button-with-right-arrow radius-10px">All Smart Board Lessons</Link>
                    </div>

                    <div>

                        <Slider {...settings}>
                            {products.map((product) => (
                                <div className="slider-item-box" key="{product.id}">
                            
                                <div className="slider-item">
                            
                                <img src={MyGlobleSetting.productimgurl + product.feature_image} alt="" className="slider-item-image"/>
                            
                                <div className="slider-item-caption">
                                    <h5 className="is-size-11 mb-15px"> {product.title}</h5>
                                    <p className="is-size-7 mb-15px">{this.substr1(product.summary)}</p>
                                    <Button className="button is-small is-size-3 is-primary is-rounded mb-0">Learn More</Button>
                                    <Link to={`/lessons/smart-board-lessons/${product.id}`} className="slider-link-button">
                                        <span className="slider-link-button-inner-text"> Learn More </span>
                                        <span className="slider-link-button-inner-icon"> <LightbulbIcon/> </span>
                                    </Link>
                                </div>
                            
                                </div>
                            
                            </div>
                            ))}
                        </Slider>

                    </div>

                </div>

                <div className="text-left mt-50px  dash-line">
                    <img src={Line} alt="" className="" />
                </div>

            </div> */}

        </Fragment>
        
        )

    }
  
}

export default SmartBoardLessons