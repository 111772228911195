import React, {Component, Fragment} from 'react'
import '../../../App.scss';
import Layout from '../../layout/layout';
import Hero from './hero/hero';
import ShopAllCardsList from './shop-all-cards-list/ShopAllCardsList';
// import { Link } from "react-router-dom"

class ShopAllCards extends Component {
  render() {
    return (
      <Fragment>
        <Layout>
          <Hero/>
          <ShopAllCardsList/>
        </Layout>
      </Fragment>
    )
  }
}

export default ShopAllCards